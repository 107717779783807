import React from "react";
import { whatsapp_click } from "../RoomphGTMTracking";

const WhatsappIcon = () => {
  return (
    <div className="icon-bar">
      <a
        href="https://wa.link/eaobk2"
        target="_blank"
        className="whatsapp m-0 p-0"
        onClick={() => whatsapp_click()}
      >
        <img src="images/Asset3.svg" width={55} alt="" />
      </a>
    </div>
  );
};

export default WhatsappIcon;
