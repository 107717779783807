import React, { useState, useEffect } from "react";
import { Row, Col } from "react-bootstrap";
import Topbar from "../../Topbar/Topbar";
import Footer from "../../footer/Footer";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import './profile.css';
import history from "../../history";

export default function Profile() {

    const [userDetails, setUserDetails] = useState({
        fname:'',
        lname:'',
        email:'',
        profile_id:''
        });
    const [user, setUser] = useState('');

    useEffect(() => {
        const fuser = JSON.parse(localStorage.getItem('user'));
        console.log("user", fuser);
        if (fuser) {
            setUserDetails({
                fname:fuser.result.result.data[0].fname,
                lname:fuser.result.result.data[0].lname,
                email:fuser.result.result.data[0].email,
                profile_id:fuser.result.result.data[0].profile_id
            })
        }else{
            history.push('/')
        }

    }, [user]);

    return (
        <section className="vh-100 d-flex flex-column justify-content-between">
            <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
            <div className="container mb-5 mt-5">
                <Row>
                    <Col className="mt-3" xs={9}>
                        <h5 className="sub-heading">Profile ID</h5>
                        <p className="profileText">{userDetails.profile_id}</p>
                    </Col>
                    <Col xs={2}>
                        <AccountCircleIcon fontSize='50px' className="profileIconP" />
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Name</h5>
                        <p className="profileText">{userDetails.fname}</p>
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Display Name</h5>
                        <p className="profileText">{userDetails.fname}</p>
                    </Col>
                </Row>
                <hr className="hrLine" />
                <Row>
                    <Col className="" xs={10}>
                        <h5 className="sub-heading">Email <button className="verified"> Verified </button></h5>
                        <p className="profileText">{userDetails.email}</p>
                        <p className="profileText">This is the email adress you use to sign in. it’s also where we send your booking confirmations.</p>
                    </Col>
                </Row>

            </div>
            <Footer />
        </section>
    );

}

