import React, { Component } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import './registeration.css';

export class VerifyAccount extends Component {
    state = {
        key: "",
        post: '',
        email: '',
        responseToPost: '',
    };


    handleKeyChanged(event) {
        this.setState({
            key: event.target.value
        });
    }
    handleKeyChanged1(event) {
        this.setState({
            email: event.target.value
        });
    }


    async handleButtonClicked(e) {
        e.preventDefault();
        var key = this.state.key;
        const response = await fetch('/api/verifyaccount', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postKey: key, email: this.state.email }),
        })
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        console.log(body.result.data[0].message);
        this.setState({ responseToPost: body });
        this.setState({
            message: body.result.data[0].message,
        })
        return body;
    }

    render() {
        return (
            <section className='sign-in-container'>
                <div className='w-100 row d-flex align-item-center'>

                    <h6 className="text-center sub-heading mb-4">Verify Account</h6>
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                {this.state.message ?
                                    <div class="alert alert-success alert-dismissible fade show ft-12" role="alert">
                                        <i class="bi bi-check-circle-fill f-18 mr-2"></i>
                                        {this.state.message}
                                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                                    </div> : null}
                                <Form>
                                    <Form.Group className="mt-3 mb-2" controlId="formBasicEmail">
                                        <Form.Control className="mb-3" type="email" value={this.state.email} onChange={this.handleKeyChanged1.bind(this)} placeholder="Enter Email" style={{ height: "30px", borderRadius: "20px 20px 20px 20px", border: "1px solid rgb(203, 203, 203)", boxShadow: "1px 1px 1px 1px rgb(205, 206, 206)", fontFamily: "Montserrat Thin", fontSize: "12px" }} />
                                    </Form.Group>
                                    <Form.Group className="mt-3 mb-2" controlId="formBasicEmail">
                                        <Form.Control className="mb-3" type="text" value={this.state.key} onChange={this.handleKeyChanged.bind(this)} placeholder="Enter Key Recieved on Email" style={{ height: "30px", borderRadius: "20px 20px 20px 20px", border: "1px solid rgb(203, 203, 203)", boxShadow: "1px 1px 1px 1px rgb(205, 206, 206)", fontFamily: "Montserrat Thin", fontSize: "12px" }} />
                                    </Form.Group>
                                    <button style={{ fontFamily: "Gotham Rounded Medium" }} className="signupBtn mb-3" type="submit" onClick={this.handleButtonClicked.bind(this)}>Verify</button>
                                </Form>
                            </div>

                        </div>
                    </div>
                </div>

            </section>
        );
    }
}

export default VerifyAccount;
