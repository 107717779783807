import React, { useState } from "react";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";

const Privacypolicy = () => {
  const [user, setUser] = useState("");

  return (
    <div>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <section className="hero_section_terms" id="section">
        <div className="container h-100">
          <div className="hero_inner h-100">
            <div className="row mx-auto text-center selection-h mb-3">
              <h1 className="main-heading text-white about-heading">
                Privacy Policy
              </h1>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-5">
          <>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
                textAlign: "center",
              }}
            >
              <strong>
                <span style={{ fontSize: 19, lineHeight: "107%" }}>&nbsp;</span>
              </strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              Ascendant HTS built the Roomph app as a Free app. This SERVICE is
              provided by Ascendant HTS at no cost and is intended for use as is
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              This page is used to inform visitors regarding policies with the
              collection, use, and disclosure of Personal Information if anyone
              decided to use our Service.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              If you choose to use our Service, then you agree to the collection
              and use of information in relation to this policy. The Personal
              Information that We collect is used for providing and improving
              the Service. We will not use or share your information with anyone
              except as described in this Privacy Policy.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              The terms used in this Privacy Policy have the same meanings as in
              our Terms and Conditions, which is accessible at our website
              unless otherwise defined in this Privacy Policy.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",
                fontSize: 15,
              }}
            >
              <strong>Information Collection and Use</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              For a better experience, while using our Service, we may require
              you to provide us with certain personally identifiable
              information. The information that We request will be retained on
              your device and is not collected by us in any way.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              The app does use third party services that may collect information
              used to identify you.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              Link to privacy policy of third-party service providers used by
              the app.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",
                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>
                <a href="https://policies.google.com/privacy">
                  <strong>Google Play Services</strong>
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/policies/analytics">
                  <strong>Google Analytics for Firebase</strong>
                </a>
              </li>
              <li>
                <a href="https://firebase.google.com/support/privacy/">
                  <strong>Firebase Crashlytics</strong>
                </a>
              </li>
            </ul>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>&nbsp;</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Log Data</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              We want to inform you that whenever you use this Service, in a
              case of an error in the app We collect data and information
              (through third party products) on your phone called Log Data. This
              Log Data may include information such as your device Internet
              Protocol (“IP”) address, device name, operating system version,
              the configuration of the app when utilizing my Service, the time
              and date of your use of the Service, and other statistics.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Cookies</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              Cookies are files with a small amount of data that are commonly
              used as anonymous unique identifiers. These are sent to your
              browser from the websites that you visit and are stored on your
              device's internal memory.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              This Service does not use these “cookies” explicitly. However, the
              app may use third party code and libraries that use “cookies” to
              collect information and improve their services. You have the
              option to either accept or refuse these cookies and know when a
              cookie is being sent to your device. If you choose to refuse our
              cookies, you may not be able to use some portions of this Service.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Service Providers</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",
                fontSize: 15,
              }}
            >
              We may employ third-party companies and individuals due to the
              following reasons:
            </p>
            <ul style={{ listStyleType: "disc" }}>
              <li>To facilitate our Service.</li>
              <li>To provide the Service on our behalf.</li>
              <li>To perform Service-related services; or</li>
              <li>To assist us in analyzing how our Service is used.</li>
            </ul>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              We want to inform users of this Service that these third parties
              have access to your Personal Information. The reason is to perform
              the tasks assigned to them on our behalf. However, they are
              obligated not to disclose or use the information for any other
              purpose.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Security</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              I value your trust in providing us your Personal Information, thus
              we are striving to use commercially acceptable means of protecting
              it. But remember that no method of transmission over the internet,
              or method of electronic storage is 100% secure and reliable, and
              We cannot guarantee its absolute security.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Changes to This Privacy Policy</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              We may update our Privacy Policy from time to time. Thus, you are
              advised to review this page periodically for any changes. We will
              notify you of any changes by posting the new Privacy Policy on
              this page.
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              <strong>Contact Us</strong>
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              If you have any questions or suggestions about my Privacy Policy,
              do not hesitate to contact us at ascendant.hts@gmail.com
            </p>
            <p
              style={{
                marginTop: "0in",
                marginRight: "0in",
                marginBottom: "8.0pt",
                marginLeft: "0in",

                fontSize: 15,
              }}
            >
              &nbsp;
            </p>
          </>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Privacypolicy;
