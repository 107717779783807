import React, { Component, useEffect , useState} from "react";
import { Container, Row, Col } from "react-bootstrap";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import { useLocation } from 'react-router-dom'
import Parser from 'html-react-parser';

function SingalBlog() {
    const location = useLocation();
    const blog = location.state.blog;
    const [user, setUser] = useState('');

    return (
        <>
            <Topbar color='#FF3D00' page='property' user={user} setUser={setUser}/>
            <section>

                <div className="container mtt-3">
                    <img src={`https://roomph.ascendant.travel/${blog.Image}`} alt="Blogs" className="singal-blog-image" />
                    <button className="travelTipsBtn my-3" style={{ fontSize: '14px', width: '100px' }} type="button"> Travel Tips </button>
                    <h1 className="main-heading mb-2">{blog.Name.replace(/<(.|\n)*?>/g, '')}</h1>
                    <div className="blog-content">
                        {Parser(blog.Description)}
                    </div>
                </div>
            </section>
            <Footer />

        </>
    )
}



// export class SingleBlog extends Component {
//     state = {
//         blogImage: '', ShortDescription: '', blogName: '',
//         post: '',
//         responseToPost: '',
//     };

//     componentDidMount() {
//         window.scrollTo(0, 0);
//         this.callApi()
//             .then(res => this.setState({
//                 blogName: res.express.Collection.Rows[0].Name,
//                 blogImage: res.express.Collection.Rows[0].Image,
//                 ShortDescription: res.express.Collection.Rows[0].ShortDescription
//             }))
//             .catch(err => console.log(err));
//     }

//     callApi = async () => {
//         const response = await fetch('/api/hello3');
//         const body = await response.json();
//         if (response.status !== 200) throw Error(body.message);

//         return body;
//     };
//     render() {
//         return (
//             <>
//                 <Topbar color='#FF3D00' page='property' />
//                 <section>
//                     <div className="container-fluid p-0 m-0">
//                         <img className="imh2" src={`https://www.roomph.pk/${this.state.blogImage}`} alt="Blogs" />
//                     </div>
//                     <div className="container">
//                         <div className="singleBlogContn">
//                             <button className="travelTipsBtn" style={{ fontSize: '14px', width: '100px' }} type="button"> Travel Tips </button>
//                             <h6 className="blogName"><a href="/singleblog">{this.state.blogName}</a></h6>
//                             <a href="/"><p className="blogtxtb blogDescription">{this.state.ShortDescription}</p></a>
//                         </div>
//                     </div>
//                 </section>
//                 <Footer />

//             </>

//         );
//     }
// }

export default SingalBlog;
