import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import FormInput from "../BasicComponents/FormInput";


function UserSignIn({ handleOpen, setUser, setOpen }) {
    const [values, setValues] = useState({
        email: "",
        password: "",
    });
    const [Loading, setloading] = useState(false)
    const [errorMSg, setErrorMsg] = useState({
        status: '',
        show: false
    });

    const inputs = [
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "Email",
            errorMessage: "It should be a valid email address!",
            label: "",
            required: true,
        },
        {
            id: 2,
            name: "password",
            type: "password",
            placeholder: "Password",
            errorMessage:
                "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
            label: "",
            required: true,
        },

    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        setloading(true)
        // console.log(values);
        fetch('/api/signin', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postEmail: values.email, postPassword: values.password }),
        }).then((SigninResp) => {
            // console.log(SigninResp);
            if (!SigninResp.ok) {
                throw Error('could not fetch the data for that resorce')
            }
            return SigninResp.json()
        }).then((JsonResp) => {
            // console.log(JsonResp);
            if (JsonResp.result.result.status[0] == "0") {
                if (JsonResp.result.result.data[0].message) {
                    setErrorMsg({
                        status: JsonResp.result.result.data[0].message + ` or verify your account !`,
                        show: true
                    })
                    setloading(false)

                } else {
                    setErrorMsg({
                        status: '<a>Please verify your account first !</a>',
                        show: true
                    })
                    setloading(false)

                }

            } else {
                localStorage.setItem("user", JSON.stringify(JsonResp));
                setUser(JsonResp.result.result.data[0].fname);
                setOpen({
                    show: false,
                    modaln: 'signin'
                })
                setloading(false)

            }
        }).catch(err => {
            console.log(err)
        })
    };

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    return (
        <div className='sign-in-container'>
            <div className='w-100'>
                <h6 className="text-center sub-heading mb-4">Sign in</h6>
                {errorMSg.show ?
                    <div class="alert alert-danger alert-dismissible fade show ft-12" role="alert">
                        <i class="bi bi-exclamation-triangle-fill mr-2 f-18"></i>
                        <Link to="/" className='alert-danger' onClick={() => handleOpen(true, 'Verify')}>{errorMSg.status}</Link>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : null}

                <form onSubmit={handleSubmit} className="row p-0 m-0">
                    {inputs.map((input) => (
                        <div className="col-12 mt-3" key={input.id}>
                            <FormInput

                                {...input}
                                value={values[input.name]}
                                onChange={onChange}
                            />
                        </div>

                    ))}
                    <div className="col-12 mt-3">
                        <button className="signupBtn" type="submit">
                            {Loading ?
                                <div class="spinner-border spinner-border-sm" role="status">
                                    <span class="visually-hidden">Loading...</span>
                                </div> : null}
                            &nbsp;&nbsp;Sign in</button>
                    </div>
                </form>
                <div className="d-flex justify-content-between px-3">

                    <Link className='text-orange ft-12' onClick={() => handleOpen(true, 'signUp')}>Create Account</Link>
                    <Link className='text-orange ft-12' onClick={() => handleOpen(true, 'forgetpass')}>Forgot Password?</Link>

                </div>
                <div className="mt-3">
                    <p className='ft-10 text-center'>By signing in, I am agreeing to Roomph’s</p>
                    <p className='ft-10 text-center'><Link to="/terms-condition" target="_blank" className='text-orange'>Terms & Conditions</Link>
                        &nbsp;&&nbsp;<Link className='text-orange' to="/terms-condition" target="_blank">Privacy Policy</Link></p>
                </div>
            </div>
        </div>
    )
}

export default UserSignIn
