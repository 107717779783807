import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import { FiUser } from "react-icons/fi";
import RoomphLogo from "../../Assests/logo.svg";
import Button from '../BasicComponents/Button';
import Logowhite from "../../Assests/logo-white.svg";
import Signup from '../registeration/signup';
import ForgetPass from '../registeration/forgotpass';
import UserSignIn from '../registeration/UserSignIn';
import VerifyAccount from '../registeration/verifyaccount';
import Dialog from '@mui/material/Dialog';
import { isMobile } from 'react-device-detect';
import { click_view_deals } from '../../RoomphGTMTracking';

export default function Topbar(props) {
  const [open, setOpen] = useState({
    show: false,
    modaln: 'signin'
  });

  const [UserProfieDrop, setUserProfieDrop] = useState(false)
  const userdropRef = useRef(null)
  useEffect(() => {
    document.addEventListener('click', handleClickOutSide);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("click", handleClickOutSide);
    };

  }, [UserProfieDrop]);

  const handleClickOutSide = (e) => {
    if (userdropRef.current) {
      if (UserProfieDrop && !userdropRef.current.contains(e.target)) {
        setUserProfieDrop(false)
      }

    }
  }
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (user) {
      props.setUser(user.result.result.data[0].fname);
    }
  }, [open]);


  const handleOpen = (show, modal) => {
    setOpen({
      ...open,
      show: show,
      modaln: modal
    });
  };
  
  const UserProfieDropfn = () => {
    setUserProfieDrop(!UserProfieDrop);
  };
  const handleClose = () => {
    setOpen({
      ...open,
      show: false,
    });
  };


  const handleLogout = () => {
    localStorage.clear();
    props.setUser('');
    // window.location.reload();
  };


  return (

    <section className={`${props.page == "home" ? "home-bar" : 'orange-bar'}`}>
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open.show}
        onClose={handleClose}
        scroll="body"
        PaperProps={{
          style: {
            borderRadius: 0, verticalAlign: isMobile ? 'center' : "top", height: isMobile ? "-webkit-fill-available" : '',
            maxWidth: isMobile ? '100%' : "55%", background: "transparent", boxShadow: "none", overflow:'hidden'
          }
        }}
      >
        {
          open.modaln == 'signin' ? <UserSignIn handleOpen={handleOpen} setUser={props.setUser} setOpen={setOpen} />
            : open.modaln == 'Verify' ? <VerifyAccount handleOpen={handleOpen} /> :
              open.modaln == 'signUp' ? <Signup handleOpen={handleOpen} setUser={props.setUser} /> :
                open.modaln == 'forgetpass' ? <ForgetPass handleOpen={handleOpen} setUser={props.setUser} setOpen={setOpen} /> : null

        }
      </Dialog>


      <div className='container-2 py-1' >
        <div className='display-flex w-100 j-between item-center'>
          <div>
            {props.page == 'property' ?
              <Link to="/" className='roomph-logo'><img className="roomphLogoH" src={Logowhite} alt="logo" /></Link> :
              <Link to="/" className='roomph-logo'><img className="roomphLogoH" src={RoomphLogo} alt="logo" /></Link>}
          </div>
          <div className="">
            <div>
              {props.page == 'property' ?
                <Link to={{ pathname: '/Deals', state: { content: 'No deals yet !' } }}>
                  <Button childern="View Deals" type="button" buttonStyle="btn-secondary"></Button>
                </Link>
                :
                <Link to={{ pathname: '/Deals', state: { content: 'No deals yet !' } }}                
                 onClick={() => click_view_deals(props.page)}
                >
                  <Button childern="View Deals" type="button" buttonStyle="btn-primary"></Button>
                </Link>
              }

              {props.user ?
                <span className="sign-in ml-1" ref={userdropRef} onClick={UserProfieDropfn}>
                  <div class="dropdownTopbar">
                    <FiUser className='profileIcon' /> {props.user} <RiArrowDropDownLine className='dropDownIcon' />
                    {UserProfieDrop ?
                      <ul className="dropdown-contentTopbar">
                        <Link to='/profile'><li className='nav-item-drop'>Profile</li></Link>
                         <hr className='my-2'></hr>
                        <li className='nav-item-drop'>
                          <Link to='/booking'>Bookings</Link>
                        </li>
                        <hr className='my-2'></hr>
                        <li className='nav-item-drop'>
                          <Link to='/Ambassador-Program'>
                            Ambassador Program
                          </Link>
                        </li>
                        <hr className='my-2'></hr>

                        <li className='nav-item-drop'>
                          <Link onClick={handleLogout}>Logout</Link>
                        </li>

                      </ul>
                      : null}
                  </div>
                </span> : <span className="sign-in ml-1" onClick={() => handleOpen(true, 'signin')}>Sign In</span>}

              {/* <span className="sign-in ml-1" onClick={props.user == '' ? () => handleOpen(true, 'signin') : UserProfieDropfn}>
                {props.user == '' ? 'Sign In' :
                  <div class="dropdownTopbar">
                    <FiUser className='profileIcon' /> {props.user} <RiArrowDropDownLine className='dropDownIcon' />
                    {UserProfieDrop ?
                      <ul className="dropdown-contentTopbar">
                        <Link to='/profile'><li className='nav-item-drop'>Profile</li></Link>
                        <hr className='my-2'></hr>
                       
                        <li className='nav-item-drop'>
                          <Link to='/Ambassador-Program'>
                            Ambassador Program
                          </Link>
                        </li>
                        <hr className='my-2'></hr>

                        <li className='nav-item-drop'>
                          <Link onClick={handleLogout}>Logout</Link>
                        </li>

                      </ul> : null}

                  </div>}
              </span> */}


              <Link className="d-lg-none" to="/download" target="_blank">
                <button className="d-lg-none buttonApp" >Use App</button>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );

}
