import React, { Component, useState, useEffect } from "react";
import { Container, Row, Col, Form } from "react-bootstrap";
import './registeration.css';
import { FcGoogle } from "react-icons/fc";
import { SiFacebook } from "react-icons/si";
import { Link } from 'react-router-dom';
import FormInput from "../BasicComponents/FormInput";

function Registration({ handleOpen, setUser }) {
    const [values, setValues] = useState({
        email: "",
        username: '',
        password: "",
        confirmPassword: '',
        phone: '',
        referralCode:''
    });
    const [Loading, setloading] = useState(false)

    const onChange = (e) => {
        setValues({ ...values, [e.target.name]: e.target.value });
    };

    const [errorMSg, setErrorMsg] = useState({
        status: '',
        show: false
    });
    const [SucessMSg, setSucessMSg] = useState({
        status: '',
        show: false
    });

    const inputs = [
        {
            id: 0,
            name: "username",
            type: "text",
            placeholder: "Enter Username",
            errorMessage:
                "Username should be 3-16 characters and shouldn't include any special character!",
            label: "",
            required: true,
        },
        {
            id: 1,
            name: "email",
            type: "email",
            placeholder: "Email",
            errorMessage: "It should be a valid email address!",
            label: "",
            required: true,
        },
        {
            id: 2,
            name: "password",
            type: "password",
            placeholder: "Enter Password",
            errorMessage:
                "Password should be 8-20 characters and include at least 1 letter, 1 number and 1 special character!",
            label: "",
            pattern: `^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{8,20}$`,
            required: true,
        },
        {
            id: 3,
            name: "confirmPassword",
            type: "password",
            placeholder: "Enter Confirm Password",
            errorMessage: "Passwords don't match!",
            label: "",
            pattern: values.password,
            required: true,
        },
        {
            id: 4,
            name: "phone",
            type: "tel",
            placeholder: "Enter Phone Number",
            errorMessage: "Format: +910123456789 or 0123456789",
            pattern: "[+0-9]{10,13}",
            title: "+910123456789 or 0123456789",
            required: true,
        },
        {
            id: 4,
            name: "referralCode",
            type: "text",
            placeholder: "Enter Referral code",
            errorMessage: "Enter Referral Code",
        },
    ];

    const handleSubmit = (e) => {
        e.preventDefault();
        setloading(true)
        console.log(values);
        fetch('/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postName: values.username, postEmail: values.email, postPhoneNo: values.phone, postPassword: values.password, referralcode:values.referralCode }),
        }).then((SigninResp) => {
            console.log(SigninResp);
            if (!SigninResp.ok) {
                throw Error('could not fetch the data for that resorce')
            }
            return SigninResp.json()
        }).then((JsonResp) => {
            console.log(JsonResp);
            if (JsonResp.result.status[0] == "0") {
                if (JsonResp.result.data[0].message) {
                    setErrorMsg({
                        status: JsonResp.result.data[0].message[0],
                        show: true
                    })
                    setloading(false)

                }

            } else if (JsonResp.result.status[0] == "1") {
                if (JsonResp.result.data[0].message) {
                    setErrorMsg({
                        status: JsonResp.result.data[0].message[0],
                        show: true
                    })
                    setloading(false)

                } else {
                    setSucessMSg({
                        status: "CONGRATULATIONS! You have signed up successfully. Please check your email.",
                        show: true
                    });
                    handleOpen(true, 'Verify')
                    setloading(false)

                }


            }
        }).catch(err => {
            console.log(err)
        })
    };
    return (
        <>
            {/* <BasicModal display={true} /> */}
            <div className="sign-in-container" style={{ borderRadius: "15px 15px 15px 15px", border: "1px solid rgb(203, 203, 203)" }}>
                <div className='w-100 row d-flex align-item-center'>
                    <div className="regFrom">
                        <h4 className="text-center">Sign Up</h4>
                        {errorMSg.show ?
                            <div class="alert alert-danger alert-dismissible fade show ft-12" role="alert">
                                <i class="bi bi-exclamation-triangle-fill mr-2 f-18"></i>
                                {errorMSg.status}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div> : null}

                        {SucessMSg.show ?
                            <div class="alert alert-success alert-dismissible fade show ft-12" role="alert">
                                <i class="bi bi-check-circle-fill f-18 mr-2"></i>
                                {SucessMSg.status}
                                <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                            </div> : null}


                        <form onSubmit={handleSubmit} className="row p-0 m-0">
                            {inputs.map((input) => (
                                <div className="col-12 mt-3" key={input.id}>
                                    <FormInput
                                        {...input}
                                        value={values[input.name]}
                                        onChange={onChange}
                                        required={input.required}
                                    />
                                </div>

                            ))}
                            <div className="col-12 mt-3">

                                <button className="signupBtn" type="submit"> {Loading ?
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div> : null}&nbsp;&nbsp;Sign Up</button>
                            </div>
                            <Row>
                                {/* //df9ead */}
                                <Col className="mt-1 aBtn" xs={6}>
                                    <aButton variant="text" onClick={() => handleOpen(true, 'Verify')} style={{ border: "none", fontSize: "11px", color: "#EF4E22", backgroundColor: "white", cursor: "pointer" }}>Verify Account</aButton>
                                </Col>
                                <Col className="mt-1 aBtn text-right" xs={6}>
                                    <aButton variant="text" size="small" onClick={() => handleOpen(true, 'signin')} style={{ border: "none", fontSize: "11px", color: "#EF4E22", backgroundColor: "white", cursor: "pointer" }}>Sign in</aButton>
                                </Col>
                            </Row>
                        </form>

                        <p className="mt-2 m-0 p-0" onClick={() => handleOpen(true, 'Verify')}>Please Verify Account Befor Sign in</p>
                        {/* <p className="mt-2 m-0 p-0">{this.state.message}</p> */}
                        {/* <p className="mt-1" >or sign up with</p> */}

                    </div>
                </div>
                <div className="mt-3">
                    <p className='ft-10 text-center'>By signing in, I am agreeing to Roomph’s</p>
                    <p className='ft-10 text-center'><Link to="/terms-condition" className='text-orange'>Terms & Conditions</Link>
                        &nbsp;&&nbsp;<Link className='text-orange' to="/terms-condition">Privacy Policy</Link></p>
                </div>
            </div>
        </>
    );
}



const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 370,
    height: 260,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};


export class Registration1 extends Component {
    state = {
        message: '', message1: '', name: "", email: "", password: "", rePassword: '', phoneNo: "",
        post: '',
        responseToPost: '',
    };

    handleNameChanged(event) {
        this.setState({
            name: event.target.value
        });
    }

    handleEmailChanged(event) {
        this.setState({
            email: event.target.value
        });
    }
    handlePasswordChanged(event) {
        this.setState({
            password: event.target.value
        });
    }

    handleRePasswordChanged(event) {
        this.setState({
            rePassword: event.target.value
        });
    }

    handlePhoneNoChanged(event) {
        this.setState({
            phoneNo: event.target.value
        });
    }


    async handleButtonClicked(e) {
        e.preventDefault();
        var name = this.state.name;
        var email = this.state.email;
        var password = this.state.password;
        var phoneNo = this.state.phoneNo;
        const response = await fetch('/api/signup', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({ postName: name, postEmail: email, postPhoneNo: phoneNo, postPassword: password }),
        })
        console.clear();
        const body = await response.json();
        if (response.status !== 200) throw Error(body.message);
        console.log(body);
        this.setState({ responseToPost: body });
        this.setState({
            message: body.result.data[0].message,
            message1: body.result.data[0].email[0]
        })
        //History.push(`/thankyou/${this.props.match.params.city}/${this.props.match.params.checkin}/${this.props.match.params.checkout}/${this.props.match.params.adults}/${this.props.match.params.rooms}/${this.props.match.params.nights}/${idd}/${name}/${email}/${phoneNo}/${uCity}/${promoCode}/${this.props.match.params.roomId}/${this.props.match.params.planId}/${this.props.match.params.roomq}/${this.props.match.params.rate}`)
    }

    render() {
        return null
    }
}

export default Registration;
