import React, { useState } from "react";
import Footer from "../footer/Footer";
import Topbar from "../Topbar/Topbar";
import CustomCheckBox from "../BasicComponents/CustomCheckBox";
import moment from "moment";
import FormInput from "../BasicComponents/FormInput";
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'
import { useHistory } from 'react-router'

function Property() {
  const MySwal = withReactContent(Swal)
  const history = useHistory()

  const [hotelinfo, sethotelinfo] = useState({
    fullname: "",
    Address: "",
    Type: "",
    NoRooms: "",
    city: "",
    contactPerson: "",
    email: "",
    Mobile: "",
    dateTime: "31",
    status: "dd",
  });
  const hoteldata = (e) => {
    sethotelinfo({ ...hotelinfo, [e.target.name]: e.target.value });
  };

  const Array = [
    {
      id: 0,
      name: "fullname",
      type: "text",
      placeholder: "Property Name",
      errorMessage: "Property Name is Required",
      label: "",
      required: true,
    },
    {
      id: 1,
      name: "Address",
      type: "text",
      placeholder: "  Property Address",
      errorMessage: "  Property Address is Required",
      label: "",
      required: true,
    },
    {
      id: 2,
      name: "NoRooms",
      type: "text",
      placeholder: "Number of Rooms",
      errorMessage: " Number of Rooms is required",
      label: "",
      required: true,
    },
    {
      id: 3,
      name: "city",
      type: "text",
      placeholder: "City",
      errorMessage: "City is required",
      label: "",
      required: true,
    },
    {
      id: 4,
      name: "contactPerson",
      type: "text",
      placeholder: "Contact person",
      errorMessage: "Contact person is Required",
      label: "",
      required: true,
    },
    {
      id: 5,
      name: "email",
      type: "email",
      placeholder: "Email",
      errorMessage: "Email is Required",
      label: "",
      required: true,
    },
    {
      id: 6,
      name: "Mobile",
      type: "phone",
      placeholder: "Contact Number",
      errorMessage: "Contact Number is Required",
      label: "",
      required: true,
    },
  ];

  const [user, setUser] = useState("");

  // const [Formerrors,seterror]=useState('')

  const hotelsubmit = (e) => {
    // seterror(validate(hotelInfo));
    e.preventDefault();
    console.log(hotelinfo);
    fetch("/api/hoteladd", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        fullname: hotelinfo.fullname,
        Address: hotelinfo.Address,
        Type: hotelinfo.Type,
        NoRooms: hotelinfo.NoRooms,
        city: hotelinfo.city,
        contactPerson: hotelinfo.contactPerson,
        email: hotelinfo.email,
        Mobile: hotelinfo.Mobile,
        dateTime: "20-09-2022",
        status: "1",
      }),
    })
      .then((response) => {
        if (!response.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        MySwal.fire({
          text: 'Thank you for sharing your property details We will get back to you as soon as possible.',
          icon: 'success'
        }).then(()=>{
          history.go(0)
        })
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <h3 className=" main-heading ft-20 mb-3 my-5 text-orange text-center">
              Why register with Roomph!
            </h3>
            <div className=" text-center mb-4">
              <img
                className="mb-2 image"
                src="images/aboutus-imgs/house-door-key.png"
              />
              <h4 className=" third-headings   text-center position-relative mb-2">
                Manage your property with complete freedom to open
                <br />
                or close inventory whenever you want
              </h4>

              <p className=" text-d-gray text-center position-relative">
                With Roomph! you get complete freedom and access to your
                property’s inventory.

                Add and Remove your propertys inventory depending upon your
                business.
              </p>
            </div>
            <div className=" text-center mb-4">
              <img
                className="mb-2 image"
                src="images/aboutus-imgs/coin-stack.png"
              />
              <h4 className="third-headings  text-center position-relative mb-2">
                Manage the pricing, policies and house rules
                <br />
                of your property for the guests
              </h4>

              <p className=" text-d-gray text-center position-relative">
                Whether you choose Dynamic Pricing based on seasonality or
                Static Pricing throughout

                the year, you stay in control of your property by updating the
                room rates

                with complete freedom.
              </p>
            </div>
            <div className=" text-center mb-4">
              <img
                className="mb-2 image"
                src="images/aboutus-imgs/increase.png"
              />
              <h4 className="third-headings  text-center position-relative mb-2">
                Our team of experienced account managers will provide
                <br />
                expert advice in how to increase your profits
              </h4>

              <p className=" text-d-gray text-center position-relative">
                At Roomph! we’ll ensure that you do not leave money on the table
                by providing expert

                advice and data to ensure you always remain competitive amongst
                your competition

                and also maximize your profits.
              </p>
            </div>
            <div className=" text-center mb-4">
              <img
                className="mb-2 image"
                src="images/aboutus-imgs/percentage.png"
              />
              <h4 className="third-headings text-center position-relative mb-2">
              Minimum commissions guaranteed              </h4>

              <p className=" ft-17 text-d-gray text-center position-relative">
                We don't add anything on top of your price so that you can price your property

                competitively and receive more reservations.
              </p>
            </div>
            <div className=" text-center mb-4">
              <img className="mb-2 image" src="images/aboutus-imgs/home.png" />
              <h4 className="third-headings   text-center position-relative mb-2">
                Guests pay at the property
              </h4>

              <p className=" text-d-gray text-center position-relative">
                Remain in control of your finances by directly collecting money

                from the guests at the property.
              </p>
            </div>
          </div>

          <div className="col-md-5">
            <h3 className="  sub-heading mt-5 mb-4 text-orange text-center">
              Register Your Property
            </h3>
            <div className="border border-color-orange border-radius px-3 py-4 ryp">
              <h6 className="third-heading text-uppercase">
                Property information
              </h6>

              <form onSubmit={hotelsubmit} className="row p-0 m-0">
                {Array.map((values) => (
                  <div className="col-12 mt-3" key={values.id}>
                    <FormInput
                      {...values}
                      label={values.label}
                      value={hotelinfo[values.name]}
                      onChange={hoteldata}
                      required={values.required}
                    />
                  </div>
                ))}
                <div className=" text-gray  mb-4 mt-3">
                  <select
                    name="Type"
                    value={hotelinfo.Type}
                    className="form-select-custom"
                    onChange={hoteldata}
                  >
                    <option default>Select type</option>
                    <option value="hotel">Hotel</option>
                    <option value="apartment">Apartment</option>
                    <option value="hostel">Hostel</option>
                    <option value="resort">Resort</option>
                    <option value="motel">Motel</option>
                    <option value="bed_and_breakfast">Bed and Breakfast</option>
                    <option value="guest_house">Guest house</option>
                    <option value="campground">Campground</option>
                    <option value="capsule_hotel">Capsule Hotel</option>
                    <option value="farm_stay">Farm stay</option>
                    <option value="holiday_park">Holiday Park</option>
                    <option value="inn">Inn</option>
                    <option value="lodge">Lodge</option>
                    <option value="self_catering_vacation_rentals">
                      Self-Catering Vacation Rentals
                    </option>
                    <option value="vacation_rental_self-catering">
                      Vacation Rental (self-catering)
                    </option>
                    <option value="private_room_in_house">
                      Private room in house
                    </option>
                  </select>
                </div>

                <div className="p-0 border-0 mb-4">

                  <CustomCheckBox value="1" name="premium" id="roomphpremium" label="I agree
                    with the terms and conditions of Roomph!" className="ml-2" />

                </div>
                <div className="p-0 border-0 text-center">
                  <button
                    type="submit"
                    className="bg-orange btn-primary btn-large d-inline-block mx-auto px-3 py-2 text-white text-center mt-3  ft-20"
                  >
                    Send the form
                  </button>
                </div>
              </form>
              {/* <form action="" method="post" onsubmit={hotelsubmit}>
                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">Property Name: *</label>
                  <input
                    type="text"
                    value={hotelInfo.fullname}
                    onChange={Addhotel}
                    placeholder=""
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="fullname"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Property Address: *
                  </label>
                  <input
                    type="text"
                    value={hotelInfo.Address}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="Address"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Property Type
                    <span className="ft-12">
                      (e.g. Guest House, Hotel, Motel etc):
                    </span>
                    *
                  </label>
                  <select
                    name="Type"
                    value={hotelInfo.Type}
                    className=" ft-18 w-100 border border-radius p-2 "
                    onChange={Addhotel}
                  >
                    <option value="">Select type</option>
                    <option value="hotel">Hotel</option>
                    <option value="apartment">Apartment</option>
                    <option value="hostel">Hostel</option>
                    <option value="resort">Resort</option>
                    <option value="motel">Motel</option>
                    <option value="bed_and_breakfast">Bed and Breakfast</option>
                    <option value="guest_house">Guest house</option>
                    <option value="campground">Campground</option>
                    <option value="capsule_hotel">Capsule Hotel</option>
                    <option value="farm_stay">Farm stay</option>
                    <option value="holiday_park">Holiday Park</option>
                    <option value="inn">Inn</option>
                    <option value="lodge">Lodge</option>
                    <option value="self_catering_vacation_rentals">
                      Self-Catering Vacation Rentals
                    </option>
                    <option value="vacation_rental_self-catering">
                      Vacation Rental (self-catering)
                    </option>
                    <option value="private_room_in_house">
                      Private room in house
                    </option>
                  </select>
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Number of Rooms: *
                  </label>
                  <input
                    type="text"
                    value={hotelInfo.NoRooms}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="NoRooms"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">City *</label>
                  <input
                    type="text"
                    value={hotelInfo.city}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="city"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className="PropertyText pl-2">Contact person: *</label>
                  <input
                    type="text"
                    value={hotelInfo.contactPerson}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="contactperson"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">Email: *</label>
                  <input
                    type="email"
                    value={hotelInfo.email}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="email"
                  />
                </div>

                <div className="p-0 border-0 mb-4">
                  <label className=" pl-2 PropertyText">
                    Contact Number: *
                  </label>
                  <input
                    type="phone"
                    value={hotelInfo.Mobile}
                    onChange={Addhotel}
                    className=" ft-18 w-100 border border-radius p-2 "
                    name="Mobile"
                  />
                </div>
                <div className="p-0 border-0 mb-4">
                  <label class=" ft-14 PropertyText">
                    <Checkbox  className='mt-1 chkBoxText p-0 ml-1' color="success" />
                    I agree with the terms and conditions of Roomph!
                  </label>
                </div>

                <br />
                <div className="p-0 border-0 text-center">
                  <button
                    type="submit"
                    onClick={hotelsubmit}
                    className="bg-orange btn-primary btn-large d-inline-block mx-auto px-3 py-2 text-white text-center  ft-20"
                  >
                    Send the form
                  </button>
                </div>
              </form> */}
            </div>

            <div className="border border-color border-radius px-3 py-3 my-4">
              <h4 className="sub-heading text-d-gray ">Your Address Matters</h4>
              <p className="PropertyText text-d-gray mb-0">
                Please make sure you enter the full address of your property,
                including building name, number, etc. Based on the information
                you provide, we’ll send an account manager to verify the
                property.
              </p>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default Property;
