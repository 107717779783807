import React, { Component, useEffect, useState } from "react";
import Footer from "../footer/Footer";
import { withRouter } from "react-router-dom";
import "./thankyou.css";
import Topbar from "../Topbar/Topbar";
import { FaCheck } from "react-icons/fa";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import moment from "moment";
import {
  percentageCalculator,
  percentagePriceCalculator,
} from "../BasicComponents/BasicFunctionScript";
import { booking_confirmed, page_view } from "../../RoomphGTMTracking";

function Thankyou(props) {
  const [user, setUser] = useState("");
  const location = useLocation();
  const {
    hotelParams,
    bookingDEtail,
    hotelDeatails,
    Total,
    contactInfo,
    roomqty,
    finalPrice,
    Tax,
    TaxAmount,
    discount,
    ratePlanId,
    discountPrice,
  } = location.state;

  useEffect(() => {
    //console.log(hotelDeatails)
    page_view("Thank You");
    booking_confirmed(
      hotelDeatails.AccommodationName[0],
      hotelDeatails.AccommodationId[0],
      hotelDeatails.Address[0],
      hotelDeatails.AccommodationTypeName[0],
      discount ? discountPrice : finalPrice,
      discount
        ? percentageCalculator(Total, discount.discount_percentage)
        : Total,
      hotelParams.rooms,
      hotelParams.nights,
      hotelParams.adults,
      ratePlanId,
      hotelDeatails.Rating[0],
      hotelDeatails.UserRating[0],
      discount ? discount.id : "",
      discount ? discount.discount_percentage : ""
    );
  }, []);
  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <section
        className="box-shadow-3"
        style={{
          backgroundColor: "white",
          borderRadius: "0px 0px 15px 15px",
          borderBottom: "1px solid rgb(205, 206, 206)",
        }}
      >
        <div className="container-3 py-2">
          <div className="px-4 d-flex align-items-center justify-content-center py-0 m-0">
            <div className="step1">1</div>
            <hr style={{ width: "89%", border: "1px solid #ff3d00" }} />
            <div className="step1">
              <FaCheck />
            </div>
            {/* <button className="step2"><SiNike /><p className="CI">Booking Confirmed</p></button> */}
          </div>
          <div className="d-flex m-0 p-0">
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "#ff8e00" }}
              >
                Customer Information
              </p>
            </div>
            <div style={{ width: "95%" }}></div>
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "#ff8e00" }}
              >
                Booking Confirmed
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-5 infor_hotel_checkout">
        <div className={isMobile ? "container-2" : "container"}>
          <div className="row">
            <div
              className="col-lg-6 col-md-6 col-12"
              style={{
                borderRadius: isMobile ? "" : "15px 0px 0px 50px",
                border: isMobile ? "" : "1px solid rgb(203, 203, 203)",
                height: isMobile ? "auto" : "400px",
                boxShadow: isMobile ? "" : "rgb(205 206 206) -1px 1px 1px 1px",
              }}
            >
              <h5 className="sub-heading mt-3 ml-2">
                Thank you, {contactInfo.fullname}! Your booking is now complete.
              </h5>
              <ul className="mt-3 final-booking f-15">
                <li>
                  You chose to pay at the property. Roomph will not charge your
                  credit card.
                </li>
                <li>
                  In the next 10 minutes, you will receive an email containing
                  your booking details.
                </li>
                <li>Your Booking ID is {bookingDEtail.BookingID}</li>
                <li>Please present this information at the check-in.</li>
                <li>
                  To help manage your bookings, you can sign-in to Roomph if you
                  did’nt book as a guest.
                </li>
              </ul>
            </div>
            <div className="col-lg-6 col-md-6 col-12">
              <div
                className="row py-lg-3 px-lg-3 px-1 py-3 ml-lg-1"
                style={{
                  backgroundColor: "#f6f6f6",
                  borderRadius: isMobile ? "13px" : "0px 15px 50px 0px",
                  border: "1px solid rgb(203, 203, 203)",
                  height: isMobile ? "auto" : "400px",
                  boxShadow: isMobile ? "" : "rgb(205 206 206) 1px 1px 1px 1px",
                }}
              >
                <div className="col-lg-7 col-md-7 col-7">
                  {/* <button className="btn btn-primary BButton" type="button"> Best Seller </button> */}
                  <h6 className="mt-2 main-heading overflow-texts">
                    {hotelDeatails.AccommodationName}
                  </h6>

                  <p className="d-flex align-item-center">
                    {" "}
                    {Array.apply(null, {
                      length: hotelDeatails.Rating
                        ? Number(hotelDeatails.Rating)
                        : 0,
                    })
                      .map(Number.call, Number)
                      .map((item) => {
                        return (
                          <img
                            key={item}
                            src={
                              process.env.PUBLIC_URL + "/images/Asset100.svg"
                            }
                            width={20}
                            style={{ marginRight: "3px" }}
                            alt=""
                          />
                        );
                      })}
                  </p>

                  <p className="mt-1 fouth-heading mb-4 f-18">
                    {hotelDeatails.Address}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  <img
                    className="hotelImgCI"
                    style={{ borderRadius: "15px" }}
                    src={hotelDeatails.hotelImages[0].Image_URLs[0].$.Image_URL}
                    alt=""
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 border-bottom pb-lg-2">
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-in:
                    </span>
                    <span className="col-lg-8 col-6">
                      {moment(hotelParams.checkin).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-out:
                    </span>
                    <span className="col-lg-8 col-6">
                      {moment(hotelParams.checkout).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>

                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Stay:</span>
                    <span className="col-lg-8 col-6">
                      {hotelParams.nights} Night
                      {hotelParams.nights > 1 ? "s" : ""}, {roomqty} Room
                      {roomqty > 1 ? "s" : ""}, {hotelParams.adults} Adult
                      {hotelParams.adults > 1 ? "s" : ""}
                    </span>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      {roomqty ? roomqty : hotelParams.rooms} Room (
                      {hotelParams.nights ? hotelParams.nights : ""} Night
                      {hotelParams.nights > 1 ? "s" : ""}):
                    </span>
                    <span className="col-lg-8 col-6">
                      {hotelDeatails["CurrencyCode"][0]}{" "}
                      {Math.round(Total).toLocaleString()}
                    </span>
                  </p>
                  {discount && discountPrice ? (
                    <p className="row f-15">
                      <span className="col-lg-4 col-6 fouth-heading">
                        Discount ({discount.discount_percentage}%):
                      </span>
                      <span className="col-lg-8 col-6">
                        <span>
                          {hotelDeatails["CurrencyCode"][0]}{" "}
                          {Math.round(discountPrice).toLocaleString()}
                        </span>
                      </span>
                    </p>
                  ) : null}

                  {discount && discountPrice ? (
                    <p className="row f-15">
                      <span className="col-lg-4 col-6 fouth-heading">
                        Subtotal:
                      </span>
                      <span className="col-lg-8 col-6">
                        <span>
                          {hotelDeatails["CurrencyCode"][0]}{" "}
                          {Math.round(Total - discountPrice).toLocaleString()}
                        </span>
                      </span>
                    </p>
                  ) : null}

                  {Tax.map((tax, index) => {
                    return (
                      <p className="row f-15" key={index}>
                        <span className="col-lg-4 col-6 fouth-heading">
                          Tax ({Number(tax.$.TaxPercent).toLocaleString()}%{" "}
                          {tax.$.TaxName}):
                        </span>
                        <span className="col-lg-8 col-6">
                          {hotelDeatails["CurrencyCode"][0]}{" "}
                          {Number(
                            (tax.$.TaxPercent * Total) / 100
                          ).toLocaleString()}
                        </span>
                      </p>
                    );
                  })}

                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Total Price:
                    </span>
                    <span className="col-lg-8 col-6">
                      {hotelDeatails["CurrencyCode"][0]}{" "}
                      {Math.round(
                        Total - discountPrice + TaxAmount
                      ).toLocaleString()}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default withRouter(Thankyou);
