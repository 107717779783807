import React, { useState } from "react";
import { DateRange } from "react-date-range";
import { addDays } from "date-fns";
import moment from "moment";
import { isMobile } from "react-device-detect";
import swal from "sweetalert";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import { BsFillExclamationCircleFill } from "react-icons/bs";
import history from "../history";
import { date_format } from "../BasicComponents/BasicFunctionScript";

function DealsSearchBox({ dealsDetail, setdealsDetail, handleClose, setOpen }) {
  const [SearchData, setSearchData] = useState({
    showCalender: false,
    showDropdown: false,
    showsearch: false,
    state: [
      {
        startDate: moment(
          dealsDetail.dealsData.valid_from,
          date_format
        ).isAfter(moment(), "day")
          ? new Date(dealsDetail.dealsData.valid_from)
          : new Date(),
        endDate: addDays(
          moment(dealsDetail.dealsData.valid_from, date_format).isAfter(
            moment(),
            "day"
          )
            ? new Date(dealsDetail.dealsData.valid_from)
            : new Date(),
          2
        ),
        key: "selection",
      },
    ],
    rooms: 1,
    adults: 2,
    childern: 0,
  });
  function handleSubmit(start, end) {
    // console.log(dealsDetail.dealsData)
    if ((dealsDetail.city, dealsDetail.hotelID)) {
      if (dealsDetail.dealsData.week_days) {
        var weekD = dealsDetail.dealsData.week_days
          .split(",")
          .map((name) => name.toLowerCase());
        if (
          weekD.includes(moment(start).format("dddd").toLowerCase()) &&
          weekD.includes(moment(end).format("dddd").toLowerCase())
        ) {
          history.push(
            `/hotels&city=${dealsDetail.city}&check_in=${moment(start).format(
              date_format
            )}&check_out=${moment(end).format(
              date_format
            )}&adult=2&rooms=1&nights=${moment(end).diff(
              moment(start),
              "days"
            )}&hotel_id=${dealsDetail.hotelID}&discount=${
              dealsDetail.dealsData.id
            }`
          );
        } else {
          handleClose();
          swal(
            `The discount on this Property is available from ${dealsDetail.dealsData.valid_from} to ${dealsDetail.dealsData.valid_till} in case your stay is on ${dealsDetail.dealsData.week_days}. Please select valid dates to avail this discount.`,
            {
              buttons: {
                cancel: "Cancel",
                catch: {
                  text: "Change Dates",
                  value: true,
                },
              },
            }
          ).then((value) => {
            if (value) {
              setOpen(true);
            }
          });
        }
      } else {
        history.push(
          `/hotels&city=${dealsDetail.city}&check_in=${moment(start).format(
            date_format
          )}&check_out=${moment(end).format(
            date_format
          )}&adult=2&rooms=1&nights=${moment(end).diff(
            moment(start),
            "days"
          )}&hotel_id=${dealsDetail.hotelID}&discount=${
            dealsDetail.dealsData.id
          }`
        );
      }
    } else {
      setOpen(false);
    }
  }

  return (
    <div className="mx-auto w-100">
      <div className="f-13 bold d-flex mb-2">
        <BsFillExclamationCircleFill className="f-20 text-orange" />
        {/* {console.log(moment(dealsDetail.dealsData.valid_from, 'YYYY-MM-DD').format('DD, MM YYYY'))} */}
        &nbsp;&nbsp; Offer valid&nbsp;
        {moment().isBetween(
          moment(dealsDetail.dealsData.valid_from, date_format),
          moment(dealsDetail.dealsData.valid_till, date_format),
          "days",
          "[]"
        )
          ? "from today"
          : "through " +
            moment(dealsDetail.dealsData.valid_from, date_format).format(
              "Do MMMM, YYYY"
            )}
        &nbsp;to&nbsp;
        {moment(dealsDetail.dealsData.valid_till, date_format).format(
          "Do MMMM, YYYY"
        )}.
      </div>
      <DateRange
        onChange={(item) => {
          if (
            item.selection.endDate.getTime() ==
            item.selection.startDate.getTime()
          ) {
            setSearchData({ ...SearchData, state: [item.selection] });
          } else {
            setSearchData({
              ...SearchData,
              state: [item.selection],
              showCalender: false,
            });
            handleSubmit(item.selection.startDate, item.selection.endDate);
          }
        }}
        rangeColors={["#FF3D00"]}
        moveRangeOnFirstSelection={false}
        dragSelectionEnabled={false}
        ranges={SearchData.state}
        showDateDisplay={false}
        months={isMobile ? 1 : 2}
        minDate={
          moment(dealsDetail.dealsData.valid_from, date_format).isAfter(
            moment(),
            "day"
          )
            ? new Date(moment(dealsDetail.dealsData.valid_from, date_format))
            : new Date()
        }
        maxDate={new Date(moment(dealsDetail.dealsData.valid_till, date_format).add(1, 'days'))}
        direction={isMobile ? "vertical" : "horizontal"}
      />
    </div>
  );
}

export default DealsSearchBox;
