import React from 'react'
const STYLES = [
    'btn-primary',
    'btn-secondary',
    'btn-topBar-secondary',
    'btn-topbar-primary'
]
const SIZE = [
    'btn-large',
    'btn-medium',

]
function Button({
    childern,
    type,
    onClick,
    buttonSize,
    buttonStyle,
    classname
}) {
    const checkButtonStyle = STYLES.includes(buttonStyle) ? buttonStyle : STYLES[0];
    const checkButtonSize = SIZE.includes(buttonSize) ? buttonSize : SIZE[0];
    const classname1=classname;
    return (
        <button className={`btn position-relative box-shadow-2 mx-1 ${classname1} ${checkButtonStyle} ${checkButtonSize}`} onClick={onClick} type={type}>{childern}</button>
    )
}

export default Button