import { initiateCheckout, getForeeCheckoutURL } from "foree-checkout";
const fuser = localStorage.getItem("user")
  ? JSON.parse(localStorage.getItem("user")).result.result.data[0]
  : "";
export const date_format = "YYYY-MM-DD";
export const destination_deals = (id) => {
  var deals = new Promise((resolve, reject) => {
    // console.log(agent_promo_id)
    fetch("/api/promotions")
      .then((r) => r.json())
      .then((d) => {
        //console.log(d);
        if (d.Status == "success") {
          var val = d.Collection.Rows.find((r) => r.IsFlat == 1);
          resolve(val ? val : "");
        } else {
          resolve("");
        }
        //console.log(dd);
      })
      .catch((err) => {
        console.log(err);
        reject(err);
      });
  });
  return deals;
};

export const validHotel = (hotelID, id) => {
  var valid = new Promise((resolve, reject) => {
    DealsArray(id).then((value) => {
      if (value.IsFlat == "1") {
        // console.log(value);
        resolve(value);
      } else {
        fetch("/api/accommodation_promotions", {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ pid: id }),
        })
          .then((check) => check.json())
          .then((d) => {
            //console.log(d)
            if (d.Status !== "failure") {
              var findHote = d.Collection.Rows.find(
                (r) => r.accommodation_BE_id == Number(hotelID)
              );
              if (findHote) {
                resolve({ ...value, ...findHote });
              } else {
                resolve("");
              }
            } else {
              resolve("");
            }
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      }
    });
  });

  return valid;
};

export const DealsArray = (id) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/promotions")
      .then((r) => r.json())
      .then((d) => {
        var dd = d.Collection.Rows;
        //console.log(dd);
        if (id) {
          resolve(dd.find((s) => s.id == id));
        } else {
          var sorterArray = new Array();
          for (let i = 0; i < dd.length; i++) {
            var check =
              sorterArray.length !== 0
                ? sorterArray.findIndex(
                    (s) =>
                      s.promotion_type.trim() == dd[i].promotion_type.trim()
                  )
                : -1;
            //console.log(check);
            if (check !== -1) {
              sorterArray[check].PromoSlider.push(dd[i]);
            } else {
              sorterArray.push({
                promotion_type: dd[i].promotion_type,
                PromoSlider: [dd[i]],
              });
            }
          }
          resolve(sorterArray);
        }
      })
      .catch((err) => {
        reject(err);
      });
  });
  return deals;
};

export function percentageCalculator(price, percentage) {
  return Number((parseInt(percentage) * parseInt(price)) / 100).toFixed(2);
}
export function percentagePriceCalculator(price, percentage) {
  return Number(
    Number(price) - Number((parseInt(percentage) * parseInt(price)) / 100)
  );
}
export const fetchReviews = (id) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/hotelReviews", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postHotelId: id }),
    })
      .then((data) => data.json())
      .then((reviews) => {
        resolve(reviews);
      });
  });
  return deals;
};

export const fetchHotelDetail = (id) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/hotelDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postHotelId: id }),
    })
      .then((Rdata) => {
        if (!Rdata.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return Rdata.json();
      })
      .then((detail) => {
        resolve(detail);
      });
  });
  return deals;
};

export const fetchAllHotel = (hotelParams) => {
  var deals = new Promise((resolve, reject) => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        postCity: hotelParams.city,
        postCheckIn: hotelParams.checkin,
        postCheckOut: hotelParams.checkout,
        postAdults: "",
        postRooms: "",
        postPriceStart: "",
        postPriceEnd: "",
        postRating: "",
        postPremium: "",
        postCategory: "",
        userRating: "",
      }),
    };

    fetch("/api/world", requestOptions)
      .then((response) => {
        if (!response.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return response.json();
      })
      .then((data) => {
        // console.log(data.Success)
        if (data.Success) {
          const searchedHotel = data.Success.result.find(
            (h) => h.AccommodationId == hotelParams.id
          );
          resolve(searchedHotel);
        } else {
          resolve("");
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return deals;
};

export const fetchPastBooking = (id, hotelId) => {
  var deals = new Promise((resolve, reject) => {
    fetch("/api/bookings", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postProfileId: id }),
    })
      .then((res) => res.json())
      .then((pastBooking) => {
        if (pastBooking.getHotels) {
          pastBooking.getHotels.Success[0].Result.map((book) => {
            if (book.AccommodationID == hotelId) {
              resolve(true);
              return;
            }
          });
        } else {
          resolve(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  });
  return deals;
};

export const createBooking = (
  email,
  phone,
  city,
  firstName,
  lastName,
  hotelParams,
  finalPrice,
  roomId,
  ratePlanId,
  extraids,
  guest_qtys,
  Total,
  ratesbydate,
  roomqty,
  isChecked,
  discount,
  discountPrice
) => {
  //console.log(discount);
  var deals = new Promise((resolve, reject) => {
    var CalculatedPrice = Total - discountPrice;
    var taxFinal = finalPrice - Total;
    // var CalculatedPrice = discount
    //   ? Number(percentagePriceCalculator(Total, discount.discount_percentage))
    //   : Number(Total);
    // var taxFinal = finalPrice - Total;
    var comments = discount
      ? `Discounted Price: Rs. ${
          Number(CalculatedPrice) + Number(taxFinal)
        } | Promo Code: ${discount.promo_code} (${
          discount.discount_percentage
        }% off on ${discount.promotion_type})`
      : "";

    fetch("/api/book", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        email: email,
        phone: phone,
        city: city,
        comments: comments,
        name: firstName,
        lastName: lastName,
        postHotelId: hotelParams.id,
        check_in: hotelParams.checkin,
        check_out: hotelParams.checkout,
        Total: Number(Total + finalPrice),
        roomId: roomId,
        ratePlanId: ratePlanId,
        extraids: extraids,
        guest_qtys: guest_qtys,
        ratesbydate: ratesbydate,
        ProfileId: fuser ? fuser.profile_id[0] : "",
        roomqty: roomqty,
        cc_type: isChecked ? 18 : 36,
        bookingstatus: isChecked ? 1 : 5,
        front_promotion_code: discount ? discount.promo_code : "",
        front_promotion_Type: discount
          ? discount.promotion_type
          : ""
          ? discount.promotion_type
          : "",
        front_promotion_Value: discount
          ? percentageCalculator(Total, discount.discount_percentage)
          : "",
      }),
    })
      .then((data) => data.json())
      .then((BookingData) => {
        //console.log(BookingData)
        resolve({
          ...BookingData.ReservationCreate.Success[0].Result[0],
          taxFinal: taxFinal,
          CalculatedPrice: CalculatedPrice,
        });
      });
  });
  return deals;
};

export const RoomphBooking = (
  BookingData,
  hotelParams,
  Jsoncode,
  finalPrice,
  contactInfo
) => {
  var roomphBooking = new Promise((resolve, reject) => {
    fetch("/api/Create_Booking", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        Ambassadorid: Jsoncode.Collection.Rows[0].ambassador_id,
        Hotelid: hotelParams.id,
        bookingNo: BookingData.BookingID[0],
        pinCode: contactInfo.AmbasoodarCode,
        Status: "pipeline",
        checkIn: hotelParams.checkin,
        checkOut: hotelParams.checkin,
        cancellationPolicy: "null",
        Refundable: "null",
        prePaid: "null",
        postPaid: "null",
        Commission: percentageCalculator(
          finalPrice,
          Jsoncode.Collection.Rows[0].commission_percentage
        ),
        Profile_Id: Jsoncode.Collection.Rows[0].profile_id,
      }),
    })
      .then((Abook) => {
        if (!Abook.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return Abook.json();
      })
      .then((JsonAbook) => {
        console.log(JsonAbook);
        resolve(JsonAbook);
      });
  });
  return roomphBooking;
};

export const validateAmbassadorCode = (contactInfo) => {
  const ambasadorValidation = new Promise((resolve, reject) => {
    fetch("/api/validateAmbassadorCode", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ code: contactInfo.AmbasoodarCode }),
    })
      .then((promo) => {
        if (!promo.ok) {
          throw Error("could not fetch the data for that resorce");
        }
        return promo.json();
      })
      .then((Jsoncode) => {
        resolve(Jsoncode);
      });
  });
  return ambasadorValidation;
};

export function initiateMyCheckout(
  BookingData,
  totalPrice,
  email,
  mobileNumber,
  promoArray
) {
  console.log(BookingData, totalPrice, email, mobileNumber, promoArray);
  let URL = getForeeCheckoutURL();
  var foreeDEtail = {
    key: "ec45ffa0-f178-49d8-a400-829650b9a7d7",
    amount: totalPrice,
    create_bill: true,
    reference_number: BookingData.BookingID,
    callback: callback,
    // 'callback_url': `http://localhost:3000/information/`,
    customer_email_address: email,
    customer_phone_number: mobileNumber,
    bill_details_id: BookingData.BookingID,
  };
  initiateCheckout(foreeDEtail, false);
  window.onmessage = (e) => {
    if (e.origin !== URL) return;
    console.log(e.data);
  };
}

function callback(param) {
  window.onmessage = (e) => {
    if (e.origin !== URL) return;
    console.log(e.data);
  };
  // var hotelId = $('#hotel_id').val();
  // var status = param.status;
  // var referenceNumber = $('#referencenumber').val();
  // var bookingNo = $('#bookingno').val();
  // var sessionId = $('#sid').val();
  // var checkin = $('.checkin').val();
  // var checkout = $('.checkout').val();
  // var domain = $('.domain').val();
  // var device = $('.device').val();
  // var promocode = $('#promocode').val();
  // var promoid = $('#promoid').val();
  // var promotionDiscount = $('#promotionDiscount').val();
  // $('#paymentresponse').val(JSON.stringify(param));
  // $.ajax({
  //     url: "https://www.roomph.pk/book/functions/bookingupdate.php",
  //     type: 'POST',
  //     data: {
  //         'hotel_Id': hotelId,
  //         'status': status,
  //         'bookingNo': bookingNo,
  //         'paymentresponse': param,
  //         'sessionId': sessionId,
  //         'checkin': checkin,
  //         'checkout': checkout,
  //         'domain': domain,
  //         'device': device,
  //         'promocode': promocode,
  //         'promoid': promoid,
  //         'promotionDiscount': promotionDiscount
  //     },
  //     beforeSend: function() {

  //     },
  //     success: function(response) {
  //         var Data = JSON.parse(response);
  //         if (param.status == 1) {
  //             Data.url
  //             window.location = '' + Data.url;
  //         }
  //         if (param.status == 2) {
  //             $('#loading').hide();
  //         }

  //     }
  // });
}
