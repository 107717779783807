import React, { useEffect } from "react";
import "aos/dist/aos.css";
import Topbar from "../Topbar/Topbar";
import Footer from "../footer/Footer";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { FaCheck } from "react-icons/fa";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { isMobile } from "react-device-detect";
import history from ".././history";
import swal from "sweetalert";
import moment from "moment";
import {
  createBooking,
  percentageCalculator,
  RoomphBooking,
  validateAmbassadorCode,
  initiateMyCheckout,
  percentagePriceCalculator,
} from "../BasicComponents/BasicFunctionScript";
import { bookeddata, page_view } from "../../RoomphGTMTracking";

function CustomerInformation() {
  const location = useLocation();
  const {
    hotelParams,
    roomId,
    Total,
    extraids,
    guest_qtys,
    ratePlanId,
    ratesbydate,
    roomqty,
    RefundStatusCheck,
    discount_final_price,
  } = location.state;

  //console.log(location.state);

  const discountPrice = discount_final_price ;
  const discount = location.state.discount;

  const [hotelDeatails, sethotelDeatails] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [bookingDEtail, setbookingDEtail] = useState(false);
  const [spinner, setspinner] = useState(false);
  const [Tax, setTax] = useState([]);
  const [Taxname, setTaxname] = useState("");
  const [isChecked, setIsChecked] = useState(false);
  const [finalPrice, setfinalPrice] = useState(Total);
  const [TaxAmount, setTaxAmount] = useState("");

  const handleOnChange = (e) => {
    setIsChecked(e.target.checked);
  };

  const [contactInfo, setContactInfo] = useState({
    fullname: "",
    phone: "",
    city: "",
    email: "",
    promo: "",
    AmbasoodarCode: "",
  });
  const [user, setUser] = useState("");

  const handleChange = (event) => {
    event.preventDefault();
    setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
  };
  useEffect(() => {
    page_view("Booking");
  }, []);

  const FinalStepTowardsBooking = async () => {
    setspinner(true);
    //var comments = "test booking"
    try {

      var user_name = contactInfo.fullname.split(" ");
      const BookingData = await createBooking(
        contactInfo.email,
        contactInfo.phone,
        contactInfo.city,
        user_name[0] ? user_name[0] : "",
        user_name[1] ? user_name[1] : "",
        hotelParams,
        finalPrice,
        roomId,
        ratePlanId,
        extraids,
        guest_qtys,
        Number(Total),
        ratesbydate,
        roomqty,
        isChecked,
        discount,
        discountPrice
      );
      if (isChecked) {
        setbookingDEtail(BookingData);
        if (contactInfo.AmbasoodarCode) {
          const Jsoncode = await validateAmbassadorCode(contactInfo);
          if (Jsoncode.Status == "failure") {
            swal("Error", "Please provide correct ambasador code", "warning");
          } else {
            const JsonAbook = await RoomphBooking(
              BookingData,
              hotelParams,
              Jsoncode,
              BookingData.taxFinal,
              contactInfo
            );
          }
        }

        bookeddata(
          hotelDeatails.AccommodationName[0],
          hotelDeatails.AccommodationId[0],
          hotelDeatails.Address[0],
          hotelDeatails.AccommodationTypeName[0],
          discount
            ? percentagePriceCalculator(
                finalPrice,
                discount.discount_percentage
              )
            : finalPrice,
          discount
            ? percentagePriceCalculator(Total, discount.discount_percentage)
            : Total,
          hotelParams.rooms,
          hotelParams.nights,
          hotelParams.adults,
          ratePlanId,
          hotelDeatails.Rating[0],
          hotelDeatails.UserRating[0],
          discount ? discount.id : "",
          discount ? discount.discount_percentage : ""
        );
        setspinner(false);
        history.push({
          pathname: "/thankyou",
          state: {
            hotelParams: hotelParams,
            Tax: Tax,
            roomqty: roomqty.split(",").length,
            contactInfo: contactInfo,
            bookingDEtail: BookingData,
            hotelDeatails: hotelDeatails,
            Total: Total,
            TaxAmount: TaxAmount,
            ratePlanId: ratePlanId,
            finalPrice: finalPrice,
            discount: discount,
            discountPrice:discountPrice
          },
        });
      } else {
        setspinner(false);
        initiateMyCheckout(
          BookingData,
          BookingData.CalculatedPrice + BookingData.taxFinal,
          contactInfo.email,
          contactInfo.phone
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  let handleSubmit = async (e) => {
    e.preventDefault();
    if (discount) {
      if (discount.valid_login == 1) {
        if (localStorage.getItem("user")) {
          if (discount.identifier) {
            var loggedInEmail = JSON.parse(localStorage.getItem("user")).result
              .result.data[0].email[0];
            if (
              loggedInEmail
                .toLowerCase()
                .includes(discount.identifier.toLowerCase())
            ) {
              if (discount.promo_valid == 1) {
                if (discount.promo_code == contactInfo.promo) {
                  //  console.log("valid user");
                  FinalStepTowardsBooking();
                } else {
                  swal("Please provide correct promotion code");
                }
              } else {
                FinalStepTowardsBooking();
              }
            } else {
              swal(
                `Please sign in to avail this discount with business email of ${discount.identifier} or proceed with regular prices.`,
                {
                  buttons: {
                    cancel: "Cancel",
                    catch: {
                      text: "Proceed",
                      value: true,
                    },
                  },
                }
              ).then((value) => {
                if (value) {
                  var ReplaceState = location.state;
                  ReplaceState.discount = "";
                  history.replace({ ...history.location, ReplaceState });
                }
              });
            }
          } else {
            if (discount.promo_valid == 1) {
              if (discount.promo_code == contactInfo.promo) {
                FinalStepTowardsBooking();
              } else {
                swal("Please provide correct promotion code");
              }
            }
          }
        } else {
          swal(
            "Please sign in to avail this discount or proceed with regular prices.",
            {
              buttons: {
                cancel: "Cancel",
                catch: {
                  text: "Proceed",
                  value: true,
                },
              },
            }
          ).then((value) => {
            if (value) {
              var ReplaceState = location.state;
              ReplaceState.discount = "";
              history.replace({ ...history.location, ReplaceState });
            }
          });
        }
      } else {
        // console.log("login is not required");
        if (discount.promo_valid == 1) {
          if (discount.promo_code == contactInfo.promo) {
            //console.log("valid user");
            FinalStepTowardsBooking();
          } else {
            swal(
              "Please provide the correct promotion code or proceed without one.",
              {
                buttons: {
                  cancel: "Cancel",
                  catch: {
                    text: "Proceed",
                    value: true,
                  },
                },
              }
            ).then((value) => {
              if (value) {
                var ReplaceState = location.state;
                ReplaceState.discount = "";
                history.replace({ ...history.location, ReplaceState });
              }
            });
          }
        } else {
          FinalStepTowardsBooking();
        }
      }
    } else {
      FinalStepTowardsBooking();
    }
  };

  useEffect(() => {
    console.log(discount);

    fetch("/api/hotelDetails", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ postHotelId: hotelParams.id }),
    })
      .then((Rdata) => Rdata.json())
      .then((detail) => {
        //console.log(detail)
        sethotelDeatails(detail.getHotels.Success[0].Result[0]);
        if (detail.getHotels.Success[0].Result[0].hoteltaxes) {
          if (
            detail.getHotels.Success[0].Result[0].hoteltaxes[0].length !== 0
          ) {
            var finalPriceCal = 0;
            detail.getHotels.Success[0].Result[0].hoteltaxes[0].Tax.map(
              (tax, index) => {
                finalPriceCal = Number(
                  finalPriceCal + Number((tax.$.TaxPercent * Total) / 100)
                );
                //setTaxname(`${Number(tax.$.TaxPercent).toLocaleString()}% ${tax.$.TaxName}`);
                if (
                  index ==
                  detail.getHotels.Success[0].Result[0].hoteltaxes[0].Tax
                    .length -
                    1
                ) {
                  // setTax(Number(tax.$.TaxPercent * Total / 100));
                  setTax(
                    detail.getHotels.Success[0].Result[0].hoteltaxes[0].Tax
                  );
                  setTaxAmount(finalPriceCal);
                  setfinalPrice(Number(finalPrice) + Number(finalPriceCal));
                  setLoading(true);
                }
              }
            );
          }
        } else {
          setLoading(true);
        }
      });
  }, []);
  return (
    <>
      <Topbar color="#FF3D00" page="property" user={user} setUser={setUser} />
      <section
        className="box-shadow-3"
        style={{
          backgroundColor: "white",
          borderRadius: "0px 0px 15px 15px",
          borderBottom: "1px solid rgb(205, 206, 206)",
        }}
      >
        <div className="container-3 py-2">
          <div className="px-4 d-flex align-items-center justify-content-center py-0 m-0">
            <div className="step1">1</div>
            <hr style={{ width: "89%", border: "1px solid #ff3d00" }} />
            <div className="step2">
              <FaCheck />
            </div>
            {/* <button className="step2"><SiNike /><p className="CI">Booking Confirmed</p></button> */}
          </div>
          <div className="d-flex m-0 p-0">
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "#ff8e00" }}
              >
                Customer Information
              </p>
            </div>
            <div style={{ width: "95%" }}></div>
            <div>
              <p
                className="f-15 line-1 text-center mt-2"
                style={{ color: "#ff8e00" }}
              >
                Booking Confirmed
              </p>
            </div>
          </div>
        </div>
      </section>
      <section className="mt-5 mb-5 infor_hotel_checkout">
        <div className={isMobile ? "container-2" : "container"}>
          {/* <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 p-0">
              <div className="signUpCapsule box-shadow-2"><u>Sign up</u> and get 20% off on your first booking.</div>
            </div>
          </div> */}
          <div className="row mt-5">
            <div
              className="col-lg-6 col-md-6 col-12"
              style={{
                backgroundColor: "#f6f6f6",
                borderRadius: isMobile ? "13px" : "15px 0px 0px 50px",
                border: "1px solid rgb(203, 203, 203)",
                height: isMobile ? "auto" : "400px",
                boxShadow: isMobile ? "" : "rgb(205 206 206) -1px 1px 1px 1px",
              }}
            >
              <div className="row py-lg-3 px-lg-3 px-1 py-3">
                <div className="col-lg-7 col-md-7 col-7">
                  {/* <button className="btn btn-primary BButton" type="button"> Best Seller </button> */}
                  {Loading ? (
                    <h6 className="mt-2 sub-heading">
                      {hotelDeatails.AccommodationName}
                    </h6>
                  ) : (
                    <Skeleton className="mt-2 main-heading"></Skeleton>
                  )}
                  {Loading ? (
                    <p className="d-flex align-item-center">
                      {" "}
                      {Array.apply(null, {
                        length: hotelDeatails.Rating
                          ? Number(hotelDeatails.Rating)
                          : 0,
                      })
                        .map(Number.call, Number)
                        .map((item) => {
                          return (
                            <img
                              key={item}
                              src={
                                process.env.PUBLIC_URL + "/images/Asset100.svg"
                              }
                              width={20}
                              style={{ marginRight: "3px" }}
                              alt=""
                            />
                          );
                        })}
                    </p>
                  ) : (
                    <Skeleton className="d-flex align-item-center"></Skeleton>
                  )}

                  <p className="mt-1 f-8 hotel-checkout-address">
                    {Loading ? hotelDeatails.Address : <Skeleton />}
                  </p>
                </div>
                <div className="col-lg-5 col-md-5 col-5">
                  {Loading ? (
                    <img
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                      src={
                        hotelDeatails.hotelImages[0].Image_URLs[0].$.Image_URL
                      }
                      alt=""
                    />
                  ) : (
                    <Skeleton
                      className="hotelImgCI"
                      style={{ borderRadius: "15px" }}
                    />
                  )}
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 border-bottom pb-2 pt-3">
                  {/* {()=>{StandardDateFn(hotelParams.checkin)}} */}
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-in:
                    </span>
                    <span className="col-lg-6 col-6 text-right">
                      {moment(hotelParams.checkin).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      Check-out:
                    </span>
                    <span className="col-lg-6 col-6 text-right">
                      {moment(hotelParams.checkout).format("Do MMM YYYY, dddd")}
                    </span>
                  </p>

                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Stay:</span>
                    <span className="col-lg-6 col-6 text-right">
                      {hotelParams.nights} Night
                      {hotelParams.nights > 1 ? "s" : ""},{" "}
                      {roomqty.split(",").length} Room
                      {roomqty.split(",").length > 1 ? "s" : ""},{" "}
                      {hotelParams.adults} Adult
                      {hotelParams.adults > 1 ? "s" : ""}
                    </span>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">
                      {roomqty ? roomqty.split(",").length : hotelParams.rooms}{" "}
                      Room ({hotelParams.nights ? hotelParams.nights : ""} Night
                      {hotelParams.nights > 1 ? "s" : ""}):
                    </span>
                    <span className="col-lg-6 col-6 text-right">
                      {Loading ? hotelDeatails["CurrencyCode"][0] : null}{" "}
                      {Math.round(Total).toLocaleString()}
                    </span>
                  </p>

                  {discount && discountPrice ? (
                    <p className="row f-15">
                      <span className="col-lg-4 col-6 fouth-heading">
                        Discount ({discount.discount_percentage}%):
                      </span>
                      <span className="col-lg-6 col-6 text-right">
                        <span>
                          {Loading
                            ? hotelDeatails["CurrencyCode"][0] == "USD"
                              ? "PKR"
                              : hotelDeatails["CurrencyCode"][0]
                            : null}{" "}
                          {Math.round(discountPrice).toLocaleString()}
                        </span>
                      </span>
                    </p>
                  ) : null}

                  {discount && discountPrice ? (
                    <p className="row f-15">
                      <span className="col-lg-4 col-6 fouth-heading">
                        Subtotal:
                      </span>
                      <span className="col-lg-6 col-6 text-right">
                        <span>
                          {Loading ? hotelDeatails["CurrencyCode"][0] : null}{" "}
                          {Math.round(Total - discountPrice).toLocaleString()}
                        </span>
                      </span>
                    </p>
                  ) : null}

                  {Loading ? (
                    Tax.map((tax, index) => {
                      return (
                        <p className="row f-15" key={index}>
                          <span className="col-lg-4 col-6 fouth-heading">
                            Tax ({Number(tax.$.TaxPercent).toLocaleString()}%{" "}
                            {tax.$.TaxName}):
                          </span>
                          <span className="col-lg-6 col-6 text-right">
                            {hotelDeatails["CurrencyCode"][0]}{" "}
                            {Number(
                              (tax.$.TaxPercent * Total) / 100
                            ).toLocaleString()}
                          </span>
                        </p>
                      );
                    })
                  ) : (
                    <Skeleton></Skeleton>
                  )}
                  <p className="row f-15">
                    <span className="col-lg-4 col-6 fouth-heading">Total:</span>
                    <span className="col-lg-6 col-6 text-right">
                      {Loading ? hotelDeatails["CurrencyCode"][0] : null}{" "}
                      {Math.round(
                        Total - discountPrice + TaxAmount
                      ).toLocaleString()}
                    </span>
                  </p>
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 d-flex align-items-center mt-3">
                  <button className="btn thankew-btn">Free Breakfast</button>
                  <button className="btn thankew-btn ml-1">Book Now Pay Later</button>
                </div> */}
              </div>
            </div>
            <div
              className="col-lg-6 col-md-6 col-sm-12"
              style={{ marginTop: isMobile ? "20px" : "" }}
            >
              <form
                onSubmit={handleSubmit}
                className="row py-lg-3 px-lg-3 px-1 py-3 ml-lg-1 box-shadow-3"
                style={{
                  borderRadius: isMobile
                    ? "15px 15px 50px 50px"
                    : "0px 15px 50px 0px",
                  border: "1px solid rgb(203, 203, 203)",
                  height: isMobile ? "auto" : "400px",
                }}
              >
                <h6 className="mb-3 f-18 fouth-heading">
                  Let us know who you are
                </h6>
                {/* formarrray */}
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    id="exampleInputEmail1"
                    placeholder="Full name *"
                    aria-describedby="emailHelp"
                    value={contactInfo.fullname}
                    name="fullname"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 mb-2">
                  <input
                    type="email"
                    class="form-control form-control-sm"
                    placeholder="Email *"
                    id="exampleInputPassword1"
                    value={contactInfo.email}
                    name="email"
                    onChange={handleChange}
                    required
                  />
                </div>
                {/* <div className="col-lg-12 col-md-12 col-sm-12 mb-3">
                  <input type="email" class="form-control form-control-sm" placeholder="Retype email *" id="exampleInputPassword1" value={contactInfo.reemail} name="reemail" onChange={handleChange} required />
                </div> */}
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2 pr-lg-0">
                  <input
                    type="tel"
                    class="form-control form-control-sm"
                    pattern="[+0-9]{10,13}"
                    title="+910123456789 or 0123456789"
                    placeholder="Phone number *"
                    value={contactInfo.phone}
                    name="phone"
                    onChange={handleChange}
                    required
                  />
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 mb-2 pl-lg-2">
                  <input
                    type="text"
                    class="form-control form-control-sm"
                    placeholder="City *"
                    id="exampleInputPassword1"
                    value={contactInfo.city}
                    name="city"
                    onChange={handleChange}
                    required
                  />
                </div>
                {discount ? (
                  discount.promo_valid == 1 ? (
                    <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pr-lg-0">
                      <input
                        type="text"
                        class="form-control form-control-sm"
                        placeholder="Promo Code (Optional)"
                        id="exampleInputPassword1"
                        value={contactInfo.promo}
                        name="promo"
                        onChange={handleChange}
                      />
                    </div>
                  ) : null
                ) : null}
                {user ? (
                  <div className="col-lg-6 col-md-6 col-sm-6 mb-3 pr-lg-2">
                    <input
                      type="text"
                      class="form-control form-control-sm"
                      placeholder="Ambassador Code"
                      id="exampleInputPassword1"
                      value={contactInfo.AmbasoodarCode}
                      name="AmbasoodarCode"
                      onChange={handleChange}
                    />
                  </div>
                ) : null}

                {RefundStatusCheck == false ? (
                  <div className="col-lg-12 col-md-12 col-sm-12 mt-12 position-relative mb-3">
                    <div className="d-inline-flex">
                      <label className="custom-checkbox-1">
                        <input
                          type="checkbox"
                          checked={isChecked}
                          onChange={handleOnChange}
                        />
                        <span></span>
                      </label>
                      <span
                        className="roboto-regular f-15 ml-2"
                        style={{ position: "relative" }}
                      >
                        I don't have a credit card
                      </span>
                    </div>
                  </div>
                ) : null}

                <div className="col-lg-12 col-md-12 col-sm-12 mb-2 ">
                  <p className="f-15">
                    We’ll send your confirmation to this email address. <br />
                    By processing with this booking, I am agreeing to Roomph’s{" "}
                    <span>
                      <Link
                        style={{ color: "#EF4E22" }}
                        to="/terms-condition"
                        target="_blank"
                      >
                        Terms & Conditions
                      </Link>
                      .
                    </span>
                  </p>
                </div>
                <div className="col-lg-12 col-md-12 col-sm-12 d-flex justify-content-center">
                  <button type="submit" className="btn btn-primary-seach">
                    {spinner ? (
                      <div
                        class="spinner-border spinner-border-sm text-light p-1 mr-2"
                        role="status"
                      >
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    ) : null}
                    Confirm Booking
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
}

export default CustomerInformation;
