import React, { useState } from 'react'
import Footer from '../footer/Footer'
import Topbar from '../Topbar/Topbar'
import moment from 'moment';
import { addDays } from 'date-fns';
import { Link } from "react-router-dom";

const About = () => {
  const [user, setUser] = useState('');

  return (

    <>
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <section className="hero_section_terms" id="section">
        <div className="container h-100">
          <div className="hero_inner h-100">
            <div className="row mx-auto text-center selection-h mb-3 ">
              <h1 className="main-heading text-white about-heading">
                About Us
              </h1>
              <p className="third-heading fadeInDown slow go text-white mt-3">
                Quality
                Consistency
                & Bargain
                Prices
              </p>
            </div>
          </div>
        </div>

      </section>
      <section className='container-fluid bg-gray m-0 py-3 discription-terms-container'>
        <div className="container">
          <div class='AboutusText'>
            <p>Roomph was founded in 2019 and is Pakistan’s first and largest hotel company that provides budget accommodation. Roomph has revolutionized the fragmented and legacy-driven budget hospitality space in Pakistan by enabling standardization of services to make your travel experience better, consistent and memorable every time. Our aim is to provide predictable, affordable and available budget-room accommodation to millions of travellers in Pakistan.</p>
            <p className='pt-2 pb-2 third-heading'>All Roomph accommodations include:</p>

            <ol>
              <li>Free Wi-Fi</li>
              <li>Air-Conditioning</li>
              <li>Well-maintained washrooms</li>
              <li>Book Now and Pay later option</li>
            </ol>
          </div>
        </div>

      </section>
      <div class="container">
        <div class="row my-5" >

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex">
            <Link to={`/destination&city=Lahore&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-1.png" class="img-fluid p-" alt="" /><p className='imageText '>Lahore</p>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Karachi&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-2.png" className="img-fluid" alt="" /><p className='imageText '>Karachi</p>
              </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Islamabad&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-3.png" className="img-fluid" alt="" /><p className='imageText '>Islamabad</p>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Rawalpindi&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-4.png" className="img-fluid" alt="" /><p className='imageText '>Rawalpindi</p>
            </Link>
          </div>
        </div>



        <div class="row my-5">

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Peshawar&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-5.png" class="img-fluid" alt="" /><p className='imageText '>Peshawar</p>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Multan&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-6.png" className="img-fluid" alt="" /><p className='imageText '>Multan</p>
            </Link>
          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Murree&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>

              <img src="images/aboutus-imgs/circle-7.png" className="img-fluid" alt="" /><p className='imageText '>Murree</p>
            </Link>

          </div>

          <div className="col-lg-3 col-md-3 col-6  p-1 d-flex ">
            <Link to={`/destination&city=Naran&check_in=${moment().format('YYYY-MM-D')}&check_out=${moment(addDays(new Date(), 2)).format('YYYY-MM-D')}&adult=2&rooms=1&nights=2`}>
              <img src="images/aboutus-imgs/circle-8.png" className="img-fluid" alt="" /><p className='imageText '>Naran</p>
            </Link>
          </div>
        </div>

        <div>
        </div>
      </div>
      <Footer />


    </>
  )


}
export default About