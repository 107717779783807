import { useState, useEffect } from 'react'
import './Style.css'
import React from "react";
import Topbar from '../Topbar/Topbar';
import Footer from '../footer/Footer';
import { right } from '@popperjs/core';
import history from '../history';
import Skeleton from 'react-loading-skeleton';
import swal from 'sweetalert';

export const Head = (props) => {
    return (
        <div className={`box-shadow-2 align-items-center justify-content-center  headtitle   mb-0 ${props.val === false ? "d-none" : ""} ${props.hom2 === false ? "d-none" : ""}`}>
            <div className='mb-2'>
                <h4 className='third-heading my-3 text-center f-15'> Ambassador Porgram</h4>
            </div>
        </div>)
}

export const FormContainer = () => {
    const handleChange = (event) => {
        event.preventDefault();
        setContactInfo({ ...contactInfo, [event.target.name]: event.target.value });
    };
    const [Loading, setLoading] = useState(false);
    const [contactInfo, setContactInfo] = useState({
        fullname: "",
        email: '',
        phone: "",
        city: "",
        Occupation: "",
        source: '',
        Institute: '',
        bwUserid: JSON.parse(localStorage.getItem('user')).result.result.data[0].profile_id[0]
    });

    const [steps, setSeteps] = useState(0);
    const [sucessMsg, setsucessMsg] = useState(false);


    const handleSubmit = (event) => {
        // console.log("inn")
        event.preventDefault();
        setSeteps(steps + 1);
        if (steps == 2) {
            setLoading(true);
            // console.log(contactInfo);
            fetch('/api/createAmbassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(contactInfo),
            }).then(createAbRes => createAbRes.json()).then((createAbResModify) => {
                console.log(createAbResModify);
                if (createAbResModify.Status == "success") {
                    console.log("in")
                    setsucessMsg(true)
                    setLoading(true);

                } else if (createAbResModify.msg) {
                    swal({
                        // title: '<i class="bi bi-emoji-frown-fill"></i>',
                        icon: "warning",
                        text: `${createAbResModify.msg}`,
                        timer: 2500,
                        showCancelButton: false,
                        showConfirmButton: false,
                    });
                    setLoading(false);

                }

            });
        }
    }

    return (
        <>
            {sucessMsg ? <Thanks /> :
                <div className="pt-0">
                    <Head />
                    <div className='container FormContainer py-lg-5 px-lg-3'>
                        <div className={"container w-100 px-3 "} >
                            <progress className={`w-100  progre`} value={steps == 0 ? 30 : steps == 1 ? 70 : 100} max={100} >30%</progress>
                        </div>
                        <div className="align-items-center justify-content-center flex-column">
                            <div className='w-100 mb-2'>
                                <h2 className="mb-0 main-heading text-center">Become an Ambassador</h2>
                                <p className='he pb-2'>Please register to become an ambassador</p>
                            </div>

                        </div>
                        <form className='px-lg-5 py-lg-4' onSubmit={handleSubmit}>
                            {steps == 0 ?
                                <>
                                    <div className='mb-2'>
                                        <input type="text" class="form-control form-control-md mb-3" name='fullname' value={contactInfo.fullname} onChange={handleChange} placeholder="Enter Name" required></input>
                                    </div>
                                    <div className='mb-2'>
                                        <input type="email" class="form-control form-control-md mb-3" name='email' value={contactInfo.email} onChange={handleChange} placeholder="Enter Email" required></input>
                                    </div>
                                    <div className='mb-2'>
                                        <input type="text" class="form-control form-control-md mb-3" name='phone' value={contactInfo.phone} onChange={handleChange} placeholder="Enter Phone Number" required></input>
                                    </div>
                                </> :
                                steps == 1 ?
                                    <>
                                        <div className='mb-2'>
                                            <input type="text" class="form-control form-control-md mb-3" value={contactInfo.Occupation} onChange={handleChange} name='Occupation' placeholder="Enter Occupation" required></input>
                                        </div>
                                        <div className='mb-2'>
                                            <input type="text" class="form-control form-control-md mb-3" value={contactInfo.city} onChange={handleChange} name='city' placeholder="Enter City" required></input>
                                        </div>
                                        <div className='mb-2'>
                                            <input type="text" class="form-control form-control-md mb-3" value={contactInfo.source} onChange={handleChange} name='source' placeholder="How did you get to know about our Ambassador Program?" required />
                                        </div>
                                    </>
                                    : steps == 2 ?
                                        <>
                                            <div className='mb-2'>
                                                <input type="text" class="form-control form-control-md mb-3" value={contactInfo.Institute} onChange={handleChange} name='Institute' placeholder="Enter Institute Name" required></input>
                                            </div>
                                        </>

                                        : null
                            }
                            <div className='container'>
                                <div className='row my-3'>
                                    <div className=' col-sm-12 col-sm-12 col-lg-12 d-flex align-items-center justify-content-end'>
                                        {/* {steps < 2 ? */}
                                        {steps !== 0 ? <button type='button' className='btn-topbar-primary-outline mx-3' onClick={() => setSeteps(steps > 0 ? steps - 1 : 0)}>Previous</button> : null}
                                        {steps !== 2 ? <button type='submit' className='btn-topbar-primary'>Next</button>
                                            : null}
                                        {steps == 2 ? <button type='submit' className='btn-topbar-primary'>{Loading ?
                                            <div class="spinner-border spinner-border-sm" role="status">
                                                <span class="visually-hidden">Loading...</span>
                                            </div> : null}&nbsp;&nbsp;Submit</button> : null}

                                        {/* } */}
                                    </div>
                                </div>
                            </div>

                        </form>

                    </div>
                </div>
            }

        </>

    )
}

export const Thanks = () => {
    const [ambassador, setambassador] = useState('')
    useEffect(() => {
        const loggedInUser = localStorage.getItem("user");
        if (loggedInUser) {
            const foundUser = JSON.parse(localStorage.getItem("user"));
            fetch('/api/check_ambassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ profile_id: foundUser.result.result.data[0].profile_id }),
            }).then(check => check.json()).then((check1) => {
               // console.log(check1)
                setambassador(check1.Ambassador)
            });
        }
    }, []);
    return (
        <section className='m-0'>
            <div class="lasts">
                <div className='container'>
                    <div className="d-flex justify-content-between pt-5 pb-3">
                        <div>
                            <h4 class="h5 text-white" ><b>Hello, {JSON.parse(localStorage.getItem("user")) ? JSON.parse(localStorage.getItem("user")).result.result.data[0].fname : ''}!</b></h4>
                            <p className='pproo'>Welcome to your Ambassador Profile</p>
                        </div>
                        <div class="percentage-container-div d-flex flex-column justify-content-center align-items-center">
                            <div class="percentage-container box-shadow-3">
                                {ambassador.Collection ? ambassador.Collection.Rows[0].commission_percentage : 0}%                            </div>
                            <p>Commission Percentage</p>
                        </div>
                    </div>

                </div>
                <div className='container pb-5 pt-3'>
                    <div className="d-flex flex-wrap justify-content-center align-items-center w-100">
                        <div class="percentage-container-div d-flex flex-column justify-content-center align-items-center">
                            <div class="percentage-container box-shadow-3">
                                {ambassador.Collection ? ambassador.Collection.CompletedBookings : 0}
                            </div>
                            <p>Bookings Completed</p>
                        </div>
                        <div class="percentage-container-div d-flex flex-column justify-content-center align-items-center">
                            <div class="percentage-container box-shadow-3">
                                {ambassador.Collection ? ambassador.Collection.PipelineBookings : 0}
                            </div>
                            <p>Bookings in Pipeline</p>
                        </div>
                        <div class="percentage-container-div d-flex flex-column justify-content-center align-items-center">
                            <div class="percentage-container box-shadow-3">
                                {ambassador.Collection ? ambassador.Collection.Rows[0].total_commission ? ambassador.Collection.Rows[0].total_commission : 0 : 0}                            </div>
                            <p>Commission Earned</p>
                        </div>
                        <div class="percentage-container-div d-flex flex-column justify-content-center align-items-center">
                            <div class="percentage-container box-shadow-3">
                                {ambassador.Collection ? ambassador.Collection.CancelledBookings : 0}                          </div>
                            <p>Bookings Cancelled</p>
                        </div>

                    </div>

                </div>
            </div>


            <div className='container code-container d-flex flex-column justify-content-center align-items-center'>
                <p className='text-center px-lg-0 px-3'>Use the following code to receive an ambassador commession on each booking.</p>
                <div className='col-7 col-md-3 col-lg-3 mx-auto mt-4'>
                    <button type="button" className='btn-primary-seach'> Code : {ambassador.Collection ? ambassador.Collection.Rows[0].code : 0} </button>
                </div>
                <p className='f-8 font-italic text-center px-lg-0 px-2 mt-4'>You'll be able to redeem your commission once your amount is equal or greater than PKR 1,000.</p>

            </div>

            {/* <div className='container'>
                <div className='row '>

                    <div className=' col-sm-12 col-sm-12 col-lg-12 d-flex mt-3'>
                        <a href='' className='ltext1 col-sm-6 col-sm-6 col-lg-6 '><p style={{ textDecoration: "underline" }}>Add Payment Method</p></a>
                        <a href='' className='ltext2  col-sm-6 flex-item-right col-sm-6 col-lg-6 '><p style={{ textDecoration: "underline" }}>More Information</p></a>


                    </div>
                </div>
            </div> */}

        </section>

    )
}

export const Embessider = () => {
    const [user, setUser] = useState('');
    const [ambassadorStatus, setambassadorStatus] = useState('loading');
    useEffect(() => {
        const foundUser = JSON.parse(localStorage.getItem("user"));
        //console.log(JSON.parse(localStorage.getItem('user')))
        if (foundUser) {
            fetch('/api/check_ambassador', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ profile_id: foundUser.result.result.data[0].profile_id }),
            }).then(check => check.json()).then((check1) => {
                setambassadorStatus(check1.Ambassador.Status);
            });
        } else {
            history.push({
                pathname: '/'
            })
        }
    }, [user]);



    return (
        <section className='vh-100 d-flex flex-column justify-content-between'>
            <section>
                <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
                {

                    ambassadorStatus === "loading" ?
                        <Skeleton /> :
                        ambassadorStatus == "failure" || ambassadorStatus == undefined ?
                            <FormContainer
                                setambassadorStatus={setambassadorStatus}
                            />
                            : <Thanks />
                }
            </section>
            <Footer />
        </section>
    )

}
