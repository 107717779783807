import React, { Suspense } from 'react';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { Loading } from 'react-loading-dot'
import { Router, Switch, Route } from "react-router-dom";

import About from './components/About-us/About';
import Property from './components/AddProperty/Property';
import BlankPage from './components/BasicComponents/BlankPage';
import Download from './components/BasicComponents/download';
import ErrorBoundary from './components/BasicComponents/ErrorBoundary'
import TestComponent from './components/BasicComponents/TestComponent'
import Blogs from './components/blogs/blogs';
import SingleBlog from './components/blogs/singleBlog';
import { Booking } from './components/Booking-Details/Booking';
import Cancellationbooking from './components/Cancellationbooking';
// import Home from './components/home/home';
import CustomerInformation from './components/customerInformation/customerInformation';
import DealsProperty from './components/Deals/DealsProperty';
import { Embessider } from './components/Embessider/Embessider';
import history from './components/history';
import Maps from './components/Maps/Maps';
// import Bookings from './components/bookings/bookings';
import Profile from './components/registeration/profile/profile';
import { Review } from './components/Review/Review';
import Privacypolicy from './components/Terms-and-condition/Privacypolicy';
import Terms from './components/Terms-and-condition/Terms';
import Thankyou from "./components/thankyou/thankyou";
import WhatsappIcon from './components/WhatsappIcon';
import ScrollToTop from './ScrollToTop';

import './App.css';
import './Assests/falti-icons/flaticon.css'
import "bootstrap-icons/font/bootstrap-icons.css";
import "bootstrap/dist/css/bootstrap.min.css";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

const Home = React.lazy(() => import('./components/home/home'));
const Results = React.lazy(() => import('./components/propertyListing/results/results'));
const Description = React.lazy(() => import('./components/propertyDetails/description/description'));
const Deals = React.lazy(() => import('./components/Deals/Deals'));
const ErrorPage = React.lazy(() => import('./ErrorPage'));


function App() {
  return (
    <div className="App position-relative">
      <WhatsappIcon />
        <ErrorBoundary >
          <Router history={history}>
            <ScrollToTop />
            <Suspense fallback={<Loading dots={4} background="rgb(241, 78, 35)" />}>
              <Switch>
                <Route path="/" exact>
                  <Home />
                </Route>
                <Route path="/destination&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights">
                  <Results />
                </Route>
                <Route path="/information">
                  <CustomerInformation />
                </Route>
                <Route path="/hotels&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms&nights=:nights&hotel_id=:id&discount=:discount">
                  <Description />
                </Route>
                <Route path="/cancellation-booking&accommodationid=:accommodationid&bookingid=:bookingid&pincode=:pincode">
                  <Cancellationbooking />
                </Route>
                <Route path="/deals/:deal-:id">
                  <DealsProperty />
                </Route>
                <Route path="/map&city=:city&check_in=:checkin&check_out=:checkout&adult=:adults&rooms=:rooms" component={Maps} />
                <Route path="/thankyou" component={Thankyou} />
                <Route path="/terms-condition" component={() => <Terms />} />
                <Route path="/privacy-policy" component={() => <Privacypolicy/>} />

                <Route path="/add-Property" component={Property} />
                <Route path="/Ambassador-Program" component={Embessider} />
                <Route path="/about-us" component={About} />
                <Route path="/blogs" component={Blogs} />
                <Route path="/Deals" component={() => <Deals />} />
                <Route path="/singleblog" component={SingleBlog} />
                <Route path="/profile" component={Profile} />
                <Route path="/booking" component={Booking} />
                <Route path="/Test" component={TestComponent} />
                <Route path="/Review" component={Review} />
                <Route path="/download" exact component={() => <Download />} />
                <Route path='*' component={() => <ErrorPage />} />

              </Switch>

            </Suspense>

          </Router>

        </ErrorBoundary>

    </div >);

}

export default App;
