import React, { useState } from "react";
import { Col, Form } from "react-bootstrap";
import FormInput from "../BasicComponents/FormInput";
import './registeration.css';
import Swal from 'sweetalert2'
import withReactContent from 'sweetalert2-react-content'

function Registration({setOpen}) {
    const MySwal = withReactContent(Swal)
    const [Loading, setloading] = useState(false)

    const [state, setState] = useState({
        email: "",
        key: '',
        password:'',
        newpassword:'',
        code:'',
        errorMSg:false,
        errorMSgStatus:'',
        resetForm:false
    })
    const onChange = (e) => {
        setState({ ...state, [e.target.name]: e.target.value });
    };
    const handleSubmit = (e) => {
        e.preventDefault();
        setloading(true)
        if(state.resetForm){
            if(state.code==state.key){
                fetch('/api/changepass', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json',
                    },
                    body: JSON.stringify({ postEmail: state.email, key: state.key, new_password:state.password, re_password:state.newpassword}),
                }).then((forgetcode) => {
                    // console.log(forgetcode);
                    if (!forgetcode.ok) {
                        throw Error('could not fetch the data for that resorce')
                    }
                    return forgetcode.json()
                }).then((JsonResp) => {
                    // console.log(JsonResp);
                    if(JsonResp.result.status[0]=="1"){
                        setOpen({
                            show: false,
                            modaln: 'signin'
                        })
                        MySwal.fire({
                            text: JsonResp.result.data[0].message[0],
                            icon: 'success'
                          });
                          setloading(false)

                    }
               
                    // if(JsonResp.result.status[0]=='1'){
                    //     setState({ ...state, key:JsonResp.result.data[0].key, resetForm:true});
                    //     console.log(state);
                    // }
               
                }).catch(err => {
                    console.log(err);
                    setloading(false)

                });
            }else{
                setState({ ...state, errorMSg:true,
                    errorMSgStatus:'Please enter correct code', });
                    setloading(false)

            }
          

        }else{
            fetch('/api/forgot', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ postEmail: state.email}),
            }).then((forgetcode) => {
                // console.log(forgetcode);
                if (!forgetcode.ok) {
                    throw Error('could not fetch the data for that resorce')
                }
                return forgetcode.json()
            }).then((JsonResp) => {
                if(JsonResp.result.status[0]=='1'){
                    setState({ ...state, key:JsonResp.result.data[0].key, resetForm:true});
                }
                setloading(false)

            }).catch(err => {
                console.log(err)
                setloading(false)

            })
        }
 
    };
    return (
        <div className='sign-in-container'>
            <div className='w-100'>
                <h6 className="text-center sub-heading mb-4">Forgot Password</h6>
                {state.errorMSg ?
                    <div class="alert alert-danger alert-dismissible fade show ft-12" role="alert">
                        <i class="bi bi-exclamation-triangle-fill mr-2 f-18"></i>
                        <span className='alert-danger'>{state.errorMSgStatus}</span>
                        <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
                    </div> : null}
                <div className='row d-flex align-item-center'>
                    <div className="regFrom col-12">
                    {state.resetForm?
                    <form onSubmit={handleSubmit} className="row p-0 m-0">
                        <div className="col-12 mt-3">
                        <FormInput onChange={onChange}  value={state.code} placeholder="Code" type='text' name='code' />

                        </div>
                        <div className="col-12 mt-3">
                        <FormInput onChange={onChange}  value={state.password} placeholder="password" type='password' name='password'/>
                        </div>
                        <div className="col-12 mt-3">
                        <FormInput onChange={onChange}  value={state.newpassword} placeholder="New password" type='password' name='newpassword'/>
                        </div>
                        <div className="col-12 mt-3">
                        <button className="signupBtn" type="submit">{Loading ?
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div> : null}&nbsp;&nbsp;Reset Password</button>
                    </div>
                    </form>
                    : 
                    <form onSubmit={handleSubmit} className="row p-0 m-0">
                        <FormInput onChange={onChange}  value={state.email} placeholder="Enter email" name='email'/>
                        <div className="col-12 mt-3">
                        <button className="signupBtn" type="submit">{Loading ?
                                    <div class="spinner-border spinner-border-sm" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div> : null}&nbsp;&nbsp;Reset Password</button>
                    </div>
                    </form>
                    
                    }
                   
                </div>

                    <div className="text-center mt-3">
                        <p className="f-10">Please enter your email in the box above. We will send you a
                            link to access further instructions.</p>
                    </div>
                </div>
            </div></div>

    )
}

export default Registration

// export class Registration extends Component {
//     state = {

//     };


//     handleEmailChanged(event) {
//         this.setState({
//             email: event.target.value
//         });
//     }
//     async handleButtonClicked(e) {
//         e.preventDefault();
//         var email = this.state.email;
//         const response = await fetch('/api/forgot', {
//             method: 'POST',
//             headers: {
//                 'Content-Type': 'application/json',
//             },
//             body: JSON.stringify({ postEmail: email }),
//         })
//         console.clear();
//         const body = await response.json();
//         if (response.status !== 200) throw Error(body.message);
//         console.log(body);
//         this.setState({ responseToPost: body });
//         this.setState({
//             message: body.result.data[0].message,
//         })
//         return body;
//     }
//     render() {
//         return (
        
//         )
//     }
// }

// export default Registration;
