import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import Footer from '../footer/Footer'
import Topbar from '../Topbar/Topbar'

function blogs() {
  const [Blogs, setblogs] = useState([]);
  const [user, setUser] = useState('');

  useEffect(() => {
    fetch('/api/hello3').then(response => response.json()).then((data) => {
      // console.log(data.express.Collection.Rows);
      setblogs(data.express.Collection.Rows)
    });
  }, []);


  return (
    <section className='vh-100 d-flex flex-column justify-content-between'>
      <Topbar color='#FF3D00' page='property' user={user} setUser={setUser} />
      <div className='container px-sm-3'>
        <h1 className="text-center main-heading mb-4 mt-5">Get Inspiration</h1>
       <div className="row">
       {Blogs.map((blog, index) => {
          if (index < 5 && index > 0) {
            return (
              <div className="blogs-outer1 col-lg-4 col-md-6 col-9 mx-auto" key={index}>
                <div className="blog-card box-shadow-2" >
                  <Link to={{
                    pathname: "/singleblog",
                    state: { blog }
                  }} className="link">
                    <div className="blog-card-inner">
                      <img className="blog-img" src={`https://roomph.ascendant.travel/${blog.Image}`} alt="Avatar" />
                      <span className='link pb-lg-3'>{blog.Name.replace(/<(.|\n)*?>/g, '')}</span>
                    </div>
                  </Link>

                </div>
              </div>

            )
          }

        })}
       </div>
       
      </div>
      <Footer />

    </section >

  )
}

export default blogs

